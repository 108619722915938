import React, { useEffect } from "react";
import CommonDivider from "../../commonComponents/CommonDivider";
import CommonSearchForm from "../../commonComponents/CommonSearchForm";
import { useState } from "react";
import CommonFormDropDownMaker from "../../commonComponents/CommonFormDropDownMaker";
import CommonTable from "../../commonComponents/CommonTable";
import URLS from "../../Fetch/URLS";
import { Form, Image } from "antd";
import { useDispatch } from "react-redux";
import { setUpdateEmployeeEl } from "./employeeSlice";
import { useNavigate, useOutletContext } from "react-router";
import { FaUserEdit } from "react-icons/fa";
import { langingPage } from "../../utils/dictionary";

const UserList = ({ permissions }) => {
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];
  const [searchQuery, setSearchQuery] = useState();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [columns, setColumns] = useState([
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },

    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Photo",
      dataIndex: "photo",
      key: "photo",
      width: 90,
      render: (val) => {
        return (
          <Image key={val} width={60} src={URLS.user_pho_base_url + val} />
        );
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (val) => {
        return val?.name;
      },
    },
    {
      title: "Center",
      dataIndex: "center_id",
      key: "role",
      render: (val) => {
        return val?.name || "-";
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 300,
    },
  ]);

  // useEffect(() => {
  //   if (permissions?.update) {
  //     setColumns((prev) => {
  //       return [
  //         ...prev,
  //         {
  //           title: dict.action[lang],
  //           dataIndex: "action",
  //           key: "action",
  //           width: 100,
  //           fixed: "right",
  //         },
  //       ];
  //     });
  //   }
  // }, [permissions]);

  useEffect(() => {
    const updatedColumns = [
      {
        title: dict.name[lang],
        dataIndex: "name",
        key: "name",
        width: 200,
      },
      {
        title: dict.email[lang],
        dataIndex: "email",
        key: "email",
        width: 200,
      },
      {
        title: dict.phone[lang],
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: dict.photo[lang],
        dataIndex: "photo",
        key: "photo",
        width: 90,
        render: (val) => (
          <Image key={val} width={60} src={URLS.user_pho_base_url + val} />
        ),
      },
      {
        title: dict.role[lang],
        dataIndex: "role",
        key: "role_name",
        render: (val) => val?.name,
      },
      {
        title: dict.center[lang],
        dataIndex: "center_id",
        key: "center",
        render: (val) => val?.name || "-",
      },
      {
        title: dict.address[lang],
        dataIndex: "address",
        key: "address",
        width: 300,
      },
    ];

    if (permissions?.update) {
      updatedColumns.push({
        title: dict.action[lang],
        dataIndex: "action",
        key: "action",
        width: 100,
        fixed: "right",
      });
    }

    setColumns(updatedColumns);
  }, [permissions, lang]);

  const actionFun = (el) => {
    return (
      <div
        className="border-none border flex w-10 p-1 justify-center items-center"
        onClick={() => {
          delete el["action"];
          dispatch(setUpdateEmployeeEl(el));
          navigate("/user-registration/" + el._id);
        }}
      >
        <span className="justify-center items-center">
          <FaUserEdit className="text-2xl text-orange-400" />
        </span>
      </div>
    );
  };

  useEffect(() => {
    dispatch(setUpdateEmployeeEl());
  }, []);

  return (
    <div>
      <CommonDivider
        label={dict.user[lang]}
        compo={permissions.create && { path: "/user-registration" }}
      ></CommonDivider>

      <CommonSearchForm
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        form={form}
        fields={[
          { name: "name", label: dict.name[lang] },
          { name: "email", label: dict.email[lang] },
          { name: "phone", label: dict.phone[lang] },
        ]}
        dropdown={
          <CommonFormDropDownMaker
            uri={"role"}
            responseLabelName="name"
            responseIdName="_id"
            selectLabel={dict.selectRole[lang]}
            selectName={"role"}
            required={false}
            RequiredMessage={dict.roleRequired[lang]}
            noStyle={true}
            extraParams={searchQuery}
            placeholder={dict.selectRole[lang]}
          ></CommonFormDropDownMaker>
        }
      ></CommonSearchForm>

      <CommonTable
        uri="user"
        reactBaseUrl="user"
        columns={columns}
        extraParams={searchQuery}
        form={form}
        scroll={{ x: 1400, y: 400 }}
        actionFun={actionFun}
      ></CommonTable>
    </div>
  );
};

export default UserList;
