import React, { useEffect, useState } from "react";
import watchImage from "../assets/Dashboard/watchImage.png";
import successReunite from "../assets/Dashboard/successReunite.png";
import unresolvedCases from "../assets/Dashboard/unresolvedCases2.png";
import casesBotImage from "../assets/Dashboard/casesBotImage.png";
import casesTopImage from "../assets/Dashboard/casesTopImage.png";
import missingFound from "../assets/Dashboard/missingFound.png";
import { langingPage } from "../utils/dictionary";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { Button, Modal, Table } from "antd";
import CommonTable from "../commonComponents/CommonTable";

const CaseStatistics = ({ statistics }) => {
  const [data, setData] = useState([]);
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  useEffect(() => {
    const data = [
      {
        img: missingFound,
        background: "#ffccc7",
        textBorderColor: "#ff4d4f",
        title: statistics?.total_lost || 0,
        subtitle: dict.total_lost_enrolled[lang],
      },
      {
        img: missingFound,
        background: "#d9eef1",
        textBorderColor: "#20aa70",
        title: statistics?.total_found || 0,
        subtitle: dict.total_found_enrolled[lang],
      },
      {
        img: successReunite,
        background: "#ece3fa",
        textBorderColor: "#dc3ccc",
        title: statistics?.total_reunion || 0,
        subtitle: dict.total_reunite_cases[lang],
      },
      {
        img: unresolvedCases,
        background: "#e1e5fe",
        textBorderColor: "#7152f3",
        title: statistics?.pending_cases || 0,
        subtitle: dict.total_unresolved_cases[lang],
      },
    ];

    setData(() => data);
  }, [statistics, lang, dict]);

  const [title, setTitle] = useState();
  const [isModelOpen, setIsModelOpen] = useState();

  const [titles, setTitles] = useState([
    "total_lost",
    "total_found",
    "total_reunion",
    "pending_cases",
  ]);

  return (
    <div
      className="mt-3 relative overflow-hidden rounded-lg bg-cover bg-no-repeat z-1"
      style={{
        background: "#edf5ff",
      }}
    >
      <TotalRecords
        isModalOpen={isModelOpen}
        setIsModalOpen={setIsModelOpen}
        title={title}
        setTitle={setTitle}
        dict={dict}
        lang={lang}
      ></TotalRecords>
      <div className="absolute bottom-0 flex mt-auto ">
        <img className="" src={casesBotImage} alt="" />
      </div>
      <div className="absolute top-0 right-0 flex mt-auto ">
        <img className="" src={casesTopImage} alt="" />
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 px-3 md:px-5 p-4">
        {data.map((el, index) => {
          return (
            <div
              key={el.title + " " + index}
              className="flex flex-col cursor-pointer lg:flex-row bg-opacity-40 z-10 justify-between items-center p-2 border-2 "
              style={{
                background: el.background,
                borderColor: el.textBorderColor,
              }}
              onClick={() => {
                setIsModelOpen(titles[index]);
                setTitle(titles[index]);
              }}
            >
              <div className="sm:flex">
                <img
                  style={{
                    width:
                      el.img === unresolvedCases
                        ? "60px"
                        : el.img === missingFound
                        ? "65px"
                        : "70px",
                  }}
                  src={el.img}
                  alt=""
                />
              </div>
              <div className="flex flex-col text-center justify-center items-center">
                <span
                  className="text-xl md:text-2xl font-bold"
                  style={{ color: el.textBorderColor }}
                >
                  {el.title}
                </span>
                <span className="font-semibold text-sm lg:text-base">
                  {el.subtitle}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TotalRecords = ({ isModalOpen, setIsModalOpen, title, setTitle, dict, lang }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (title) {
      navigate("/dashboard/page=1/limit=25");
    }
  }, [title]);

  const columns = [
    {
      title: dict.ticket_number[lang],
      dataIndex: "complaint_number",
      key: "complaint_number",
    },
    {
      title: dict.first_name[lang],
      dataIndex: "first_name",
      key: "name",
    },
    {
      title: dict.last_name[lang],
      dataIndex: "last_name",
      key: "name",
    },
    {
      title: dict.gender[lang],
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: dict.age[lang],
      dataIndex: "age",
      key: "age",
    },
    {
      title: dict.sector[lang],
      dataIndex: "center_id",
      key: "sector",
      render: (val) => {
        return val?.sector_id?.name || "-";
      },
    },
  ];

  const [maxCount, setMaxCount] = useState({ lost: 0, found: 0 });

  return (
    <Modal
      width={1000}
      title={dict.total_statistics[lang]}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <div className="flex gap-2 my-2">
        <Button
          className={
            title === "total_lost"
              ? "bg-orange-400 text-violet-950 font-semibold"
              : "bg-gray-300 text-black font-semibold"
          }
          onClick={() => setTitle(() => "total_lost")}
        >
          {dict.total_lost[lang]}
        </Button>
        <Button
          className={
            title === "total_found"
              ? "bg-orange-400 text-violet-950 font-semibold"
              : "bg-gray-300 text-black font-semibold"
          }
          onClick={() => setTitle(() => "total_found")}
        >
          {dict.total_found[lang]}
        </Button>
        <Button
          className={
            title === "total_reunion"
              ? "bg-orange-400 text-violet-950 font-semibold"
              : "bg-gray-300 text-black font-semibold"
          }
          onClick={() => setTitle(() => "total_reunion")}
        >
          {dict.total_reunion[lang]}
        </Button>
        <Button
          className={
            title === "pending_cases"
              ? "bg-orange-400 text-violet-950 font-semibold"
              : "bg-gray-300 text-black font-semibold"
          }
          onClick={() => setTitle(() => "pending_cases")}
        >
          {dict.total_pending[lang]}
        </Button>
      </div>
      <div key={title}>
        <div
          className={
            title &&
            (title?.includes("reun") || title.includes("pending")) &&
            "grid gap-3 grid-cols-1 lg:grid-cols-2"
          }
        >
          {
            <div>
              <div className="font-semibold my-1">
                {title?.includes("reunion")
                  ? dict.lost_reunion[lang]
                  : title?.includes("pending")
                  ? dict.lost_pending_cases[lang]
                  : ""}
              </div>
              <CommonTable
                uri={
                  title === "total_lost"
                    ? title
                    : title === "total_found"
                    ? title
                    : title === "total_reunion"
                    ? "total_lost_reunion"
                    : "total_lost_pending"
                }
                columns={columns}
                reactBaseUrl={"dashboard"}
                scroll={{ x: 600, y: 400 }}
                pagination={
                  !(title?.includes("reunion") || title?.includes("pending"))
                }
                setTotalCount={(val) => {
                  setMaxCount((prev) => {
                    return { ...prev, lost: val };
                  });
                }}
              ></CommonTable>
            </div>
          }

          {title && (title?.includes("reun") || title.includes("pending")) && (
            <div>
              <div className="font-semibold my-1">
                {title?.includes("reunion")
                  ? dict.found_reunion[lang]
                  : dict.found_pending_cases[lang]}
              </div>
              <CommonTable
                uri={
                  title === "total_reunion"
                    ? "total_found_reunion"
                    : "total_found_pending"
                }
                columns={columns}
                reactBaseUrl={"dashboard"}
                scroll={{ x: 600, y: 400 }}
                setTotalCount={(val) => {
                  setMaxCount((prev) => {
                    return { ...prev, found: val };
                  });
                }}
                totalDocsInput={
                  maxCount.lost > maxCount.found
                    ? maxCount.lost
                    : maxCount.found
                }
              ></CommonTable>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CaseStatistics;
