import { getData } from "../Fetch/Axios";
import URLS from "../Fetch/URLS.js";

const optionsMaker = async (
  uri,
  labelFieldName,
  idFieldName,
  setOptions,
  params
) => {
  const path = URLS[uri];
  const response = await getData(path + params);

  if (response?.docs) {
    let finalList = [];

    for (const element of response?.docs) {
      if (element[labelFieldName]) {
        finalList.push({
          label: element[labelFieldName],
          value: element[idFieldName],
        });
      }
    }

    setOptions(() => finalList);
  }
};

export default optionsMaker;
