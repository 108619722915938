const languages = [
  "Assamese",
  "Bengali",
  "Bhojpuri",
  "Bodo",
  "Chhattisgarhi",
  "Dogri",
  "Gujarati",
  "Hindi",
  "Kannada",
  "Kashmiri",
  "Khasi",
  "Konkani",
  "Maithili",
  "Malayalam",
  "Manipuri (Meitei)",
  "Marathi",
  "Marwari",
  "Magahi",
  "Nepali",
  "Odia",
  "Punjabi",
  "Rajasthani",
  "Sanskrit",
  "Santali",
  "Sindhi",
  "Tamil",
  "Telugu",
  "Tulu",
  "Urdu",
  // Non-Indian languages sorted alphabetically
  "English",
  "Filipino (Tagalog)",
  "French",
  "German",
  "Hausa",
  "Italian",
  "Japanese",
  "Korean",
  "Portuguese",
  "Russian",
  "Thai",
  "Turkish",
  "Vietnamese",
  "Western Punjabi (Lahnda)",
  "Wu Chinese",
  "Yue Chinese (Cantonese)",
  "Egyptian Arabic",
  "Iranian Persian (Farsi)",
  "Burmese",
  "Javanese",
];

const getLanguages = () => {
  const uniqueLanguages = [...new Set(languages)];

  const languageObjects = uniqueLanguages.map((lang) => ({
    label: lang,
    value: lang,
  }));
  return languageObjects;
};

export default getLanguages;
