import React from "react";
import CommonSearchForm from "../../commonComponents/CommonSearchForm";
import { useState } from "react";
import CommonTable from "./../../commonComponents/CommonTable";
import { Form, Table } from "antd";
import CallingStatus from "./CallingStatus";
import {
  formatDateFunction,
  validateMobileNumber,
} from "../../utils/commonFunctions";
import CommonDivider from "../../commonComponents/CommonDivider";
import { langingPage } from "../../utils/dictionary";
import { useOutletContext } from "react-router";

const CallingHistory = () => {
  const [searchQuery, setSearchQuery] = useState();
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  const [form] = Form.useForm();

  const columns = [
    {
      title: dict.ticket_number[lang],
      dataIndex: "complaint_number",
      key: "complaint_number",
    },
    // {
    //   title: "Center",
    //   dataIndex: "center_id",
    //   key: "center_id",
    //   render: (val) => {
    //     return val?.name || "-";
    //   },
    // },
    {
      title: dict.first_name[lang],
      dataIndex: "first_name",
      key: "name",
    },
    {
      title: dict.last_name[lang],
      dataIndex: "last_name",
      key: "name",
    },
  ];

  const expandedRowRender = (records) => {
    const expandedColumns = [
      {
        title: "Center",
        dataIndex: "center",
        key: "center",
        render: (val) => {
          return val.name;
        },
      },
      {
        title: "Operator",
        dataIndex: "operator",
        key: "name",
        render: (val) => {
          return val.name;
        },
      },
      {
        title: "Remark",
        dataIndex: "remark",
        key: "remak",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "gender",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: (val) => {
          return formatDateFunction(val, true);
        },
      },
    ];

    return (
      <div>
        <div className="font-semibold">Total: {records.length}</div>
        <Table columns={expandedColumns} dataSource={records} rowKey="_id" />
      </div>
    );
  };

  return (
    <div>
      <CommonDivider
        label={dict.calling_history[lang]}
        backCompo={{ path: "/calling-status" }}
      ></CommonDivider>
      <CommonSearchForm
        setSearchQuery={setSearchQuery}
        form={form}
        searchQuery={searchQuery}
        fields={[{ name: "complaint_number", label: dict.ticket_number[lang] }]}
      ></CommonSearchForm>
      <div>
        <CommonTable
          uri={"callingHistory"}
          columns={columns}
          reactBaseUrl={"calling-history"}
          extraParams={searchQuery}
          scroll={{ x: 600, y: 400 }}
          spreadOptions={["lostPerson"]}
          expandable={{
            expandedRowRender: (record) =>
              expandedRowRender(record.callingStatus),
            rowExpandable: () => true,
          }}
        ></CommonTable>
      </div>
    </div>
  );
};

export default CallingHistory;
