import React from "react";
import CommonFormDropDownMaker from "../../commonComponents/CommonFormDropDownMaker";

const CenterDropdown = ({
  userRole,
  dict,
  lang,
  defaultValue,
  noStyle,
  lable,
}) => {
  return (
    <div>
      <CommonFormDropDownMaker
        uri={"center"}
        responseLabelName="name"
        responseIdName="_id"
        selectLabel={lable}
        selectName={"center_id"}
        required={true}
        RequiredMessage={dict.please_select_center[lang]}
        placeholder={"Select center"}
        defaultValue={defaultValue}
        noStyle={noStyle}
        lable={lable}
      ></CommonFormDropDownMaker>
    </div>
  );
};

export default CenterDropdown;
