import { Button, DatePicker } from "antd";
import React from "react";
import ViewReports from "./ViewReports";
import { useState } from "react";
import dayjs from "dayjs";
import { useEffect } from "react";
import { getData } from "../Fetch/Axios";
import GroupedBarChart from "./GroupBarChart";
const weekFormat = "YYYY/MM/DD";

const GenderGroupBar = ({ URL, age = [] }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [series, setSeries] = useState("s1");

  const [barChartDetails, setBarChartDetails] = useState();

  const [dateRange, setDateRange] = useState(
    dayjs().endOf("week").format(weekFormat)
  );

  const [barChartDetailsDocs, setBarChartDetailsDocs] = useState({
    total_lost: [],
    total_found: [],
    total_reunion: [],
    total_pending: [],
  });

  const disableFutureDates = (current) => {
    return current && current > dayjs().endOf("week");
  };

  const customWeekStartEndFormat = (value) => {
    return `${dayjs(value).startOf("week").format(weekFormat)} ~ ${dayjs(value)
      .endOf("week")
      .format(weekFormat)}`;
  };

  const getBarChartDetails = async (date) => {
    const get_weeks_statistics = await getData(URL + "?start_date=" + date);

    if (!get_weeks_statistics?.docs) {
      return;
    }

    const total_reunion = [];

    for (
      let index = 0;
      index < get_weeks_statistics?.docs?.total_reunion_lost.length;
      index++
    ) {
      total_reunion.push({
        name: get_weeks_statistics.docs.total_reunion_lost[index].name,
        data: get_weeks_statistics.docs.total_reunion_lost[index].data.map(
          (el, i) =>
            el + get_weeks_statistics.docs.total_reunion_found[index].data[i]
        ),
        docs: [
          ...get_weeks_statistics.docs.total_reunion_lost[index].docs,
          ...get_weeks_statistics.docs.total_reunion_found[index].docs,
        ],
      });
    }

    const total_pending = [];

    for (
      let index = 0;
      index < get_weeks_statistics?.docs?.total_pending_lost.length;
      index++
    ) {
      total_pending.push({
        name: get_weeks_statistics.docs.total_pending_lost[index].name,
        data: get_weeks_statistics.docs.total_pending_lost[index].data.map(
          (el, i) => {
            return (
              el + get_weeks_statistics.docs.total_pending_found[index].data[i]
            );
          }
        ),
        docs: [
          ...get_weeks_statistics.docs.total_pending_lost[index].docs,
          ...get_weeks_statistics.docs.total_pending_found[index].docs,
        ],
      });
    }

    const finalObj = {
      s1: get_weeks_statistics.docs.total_lost,
      s2: get_weeks_statistics.docs.total_found,
      s3: total_reunion,
      s4: total_pending,
    };

    setBarChartDetails(() => finalObj);

    const getTotalDocs = (obj) => {
      let finalDocs = [];

      for (const iterator of obj) {
        let allDocs = [];

        for (const el of iterator.docs) {
          allDocs = [...allDocs, ...el];
        }

        finalDocs = [...finalDocs, ...allDocs];
      }

      return finalDocs;
    };

    const total_lost_docs = getTotalDocs(get_weeks_statistics.docs.total_lost);
    const total_found_docs = getTotalDocs(
      get_weeks_statistics.docs.total_found
    );

    const total_reunion_docs = getTotalDocs(total_reunion);
    const total_pending_docs = getTotalDocs(total_pending);

    function getLocalDateString(dateString) {
      const date = new Date(dateString);
      const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      return localDate.toISOString().split("T")[0];
    }
    const docs = {
      total_lost: total_lost_docs.map((el, index) => {
        return {
          ...el,
          key: el.first_name + index,
          createdAt: getLocalDateString(el.createdAt),
        };
      }),

      total_found: total_found_docs.map((el, index) => {
        return {
          ...el,
          key: el.first_name + index,
          createdAt: getLocalDateString(el.createdAt),
        };
      }),
      total_reunion: total_reunion_docs.map((el, index) => {
        return {
          ...el,
          key: el.first_name + index,
          createdAt: getLocalDateString(el.createdAt),
        };
      }),
      total_pending: total_pending_docs.map((el, index) => {
        return {
          ...el,
          key: el.first_name + index,
          createdAt: getLocalDateString(el.createdAt),
        };
      }),
    };

    setBarChartDetailsDocs(() => docs);
  };

  useEffect(() => {
    if (dateRange) {
      getBarChartDetails(dateRange);
    }
  }, [dateRange]);

  return (
    <div className="w-full">
      <div
        className="flex w-full justify-between relative flex-wrap pl-7 "
        style={{ top: "80px" }}
      >
        <Button
          className="rounded-none"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          View Records
        </Button>
        <DatePicker
          className="mr-7"
          onChange={(date, dateString) => {
            setDateRange(dateString.split(" ")[2]);
          }}
          defaultValue={dayjs()}
          format={customWeekStartEndFormat}
          picker="week"
          disabledDate={disableFutureDates}
        />{" "}
      </div>
      <div className="-mt-7">
        <GroupedBarChart
          setDateRange={setDateRange}
          details={barChartDetails}
          setSeries={setSeries}
          age={age}
        ></GroupedBarChart>
      </div>
      <ViewReports
        docs={barChartDetailsDocs}
        series={series}
        dateRange={dateRange}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      ></ViewReports>
    </div>
  );
};

export default GenderGroupBar;
