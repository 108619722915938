import React, { useRef } from "react";
import Webcam from "react-webcam";
import { langingPage } from "../utils/dictionary";
import { useOutletContext } from "react-router";

const WebcamCapture = ({ setImage, image, setImageSrcMain = () => {} }) => {
  const webcamRef = useRef(null);
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  const capture = () => {
    if (!image) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc);
      setImageSrcMain(imageSrc);
    } else {
      setImage();
      setImageSrcMain();
    }
  };

  return (
    <div className="flex flex-col w-full justify-center">
      {!image && (
        <>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={280}
            className="border rounded"
          />

          <div
            onClick={capture}
            className=" text-center relative -mt-7  w-full px-2 py-1 bg-blue-500 bg-opacity-30 text-white rounded text-sm"
          >
            {dict.capture_image[lang]}
          </div>
        </>
      )}

      {image && (
        <div className="w-52 ">
          <div className="flex w-full overflow-hidden justify-center items-center">
            <img src={image} alt="Captured" className="border rounded" />
          </div>
          <button
            onClick={capture}
            className=" text-center  relative  z-50 -mt-9  w-full px-2 py-1 bg-blue-500 bg-opacity-30 text-white rounded text-sm"
          >
            Retake
          </button>
        </div>
      )}
    </div>
  );
};

export default WebcamCapture;
