import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./Login/Login";
import Layout from "./AppLayout/Layout";
import Converter from "./BhashiniApi/Converter";
import SearchLostPerson from "./search/lost_person/SearchLostPerson";
import { useEffect, useState } from "react";
import getRoutes from "./utils/Routes";
import LandingPageUpdated from "./landingPage/LandingPageUpdated";
import ForgotPassword from "./Login/FogotPassword/ForgotPassword";
import ChangePassword from "./Login/FogotPassword/ChangeYourPassword";
import NoPermissionsPage from "./ErrorPage";
import CallingHistory from "./Handover/CallinStatus/CallingHistory";
import Privacy from "./privacy/Privacy";
import DeleteAccount from "./privacy/DeleteAccount";
import ContactUs from "./privacy/ContactUs";

function fallbackRender({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
    </div>
  );
}

function App() {
  const token = localStorage.getItem("sessionToken");
  const [protectedRoutes, setProtectedRoutes] = useState([]);
  const [canProceed, setCanProceed] = useState(false);

  useEffect(() => {
    let permissionInterval = setInterval(() => {
      let permissions = JSON.parse(localStorage.getItem("permissions"));
      if (permissions) {
        setProtectedRoutes(() => getRoutes(permissions));
        clearInterval(permissionInterval);
        const path = window.location.pathname;
        if (path === "/login") {
          window.location.href = "/";
        }
      }
    }, 500);
  }, [canProceed]);

  return (
    <BrowserRouter>
      <ErrorBoundary fallbackRender={fallbackRender} onReset={(details) => {}}>
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                to={!token ? "/welcome-to-lost-and-found-2025" : "/dashboard"}
              />
            }
          ></Route>

          <Route path="/" element={<Layout></Layout>}>
            <Route
              path="/welcome-to-lost-and-found-2025"
              element={<LandingPageUpdated></LandingPageUpdated>}
            ></Route>

            {protectedRoutes}

            <Route
              path="bhashini-api"
              element={<Converter></Converter>}
            ></Route>

            <Route
              path="calling-history/:page?/:limit?/:query?"
              element={<CallingHistory></CallingHistory>}
            ></Route>

            <Route
              path="lost-found-search/:page?/:limit?/:query?"
              element={<SearchLostPerson></SearchLostPerson>}
            ></Route>

            <Route
              path="*"
              element={<NoPermissionsPage></NoPermissionsPage>}
            ></Route>
          </Route>

          <Route
            path="login"
            element={
              <Login
                setCanProceed={setCanProceed}
                canProceed={canProceed}
              ></Login>
            }
          ></Route>

          <Route
            path="forgot-password/"
            element={<ForgotPassword></ForgotPassword>}
          ></Route>

          <Route path="privacy" element={<Privacy></Privacy>}></Route>

          <Route
            path="delete-account"
            element={<DeleteAccount></DeleteAccount>}
          ></Route>

          <Route path="contact-us" element={<ContactUs></ContactUs>}></Route>

          <Route
            path="reset-password/:token"
            element={<ChangePassword></ChangePassword>}
          ></Route>
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
