import React, { useState } from "react";
import { Form, Radio } from "antd";
import { langingPage } from "../../utils/dictionary";
import { useOutletContext } from "react-router";

const SourceRadio = () => {
  const [value, setValue] = useState("");
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  const pointOfSourceOptions = [
    { value: "NGO - एनजीओ", label: dict.ngo[lang] },
    { value: "Announcement - घोषणा", label: dict.announcement[lang] },
    { value: "Display - प्रदर्शन", label: dict.display[lang] },
    { value: "Website - वेबसाइट", label: dict.website[lang] },
    { value: "Social Media - सोशल मीडिया", label: dict.social[lang] },
    { value: "Police - पुलिस", label: dict.police[lang] },
    { value: "Mixed - मिश्रित", label: dict.mixed[lang] },
    { value: "Other - अन्य", label: dict.other[lang] },
  ];

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div>
      <Form.Item
        name="receiver_point_of_source"
        label={dict.point_of_source[lang]}
        rules={[
          {
            required: true,
            message: dict.point_of_source_required[lang],
          },
        ]}
      >
        <Radio.Group onChange={onChange} value={value}>
          <div className="flex gap-2">
            {pointOfSourceOptions.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </div>
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default SourceRadio;
