import { Spin } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const NoPermissionsPage = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 5000);
  }, []);
  return (
    <Spin className={!show && "mt-20"} spinning={!show}>
      <div className="flex flex-col items-center justify-center  ">
        {show && (
          <div
            className={`bg-white shadow-md rounded-lg p-8 text-center ${
              show && "mt-10"
            }`}
          >
            <div className="text-red-500 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-16 h-16 mx-auto"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.875 18.825A4.5 4.5 0 119 7.5m0 0a4.5 4.5 0 016.75 3.25m-6.75-3.25v.75M9 12h.008v.008H9V12z"
                />
              </svg>
            </div>

            <h1 className="text-2xl font-bold text-gray-800 mb-2">
              Access Denied
            </h1>

            <p className="text-gray-600">
              You do not have the necessary permissions to access this module.
            </p>

            <button
              className="mt-6 px-6 py-1 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
              onClick={() => (window.location.href = "/")}
            >
              Home
            </button>
          </div>
        )}
      </div>{" "}
    </Spin>
  );
};

export default NoPermissionsPage;
