import React from "react";

const Privacy = () => {
  return (
    <div
      className="privacy-policy-container"
      style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}
    >
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
        Privacy Policy
      </h1>
      <p style={{ textAlign: "center", marginBottom: "40px" }}>
        Effective Date: 13-12-2024
      </p>

      <div style={{ maxWidth: "800px", margin: "0 auto", lineHeight: "1.6" }}>
        <p>
          Welcome to the Khoya Paya MahaKumbh25 app. This Privacy Policy
          explains how Prayagraj Mela Authority collects, uses, and protects any
          information that you provide while using this app. Your privacy is
          important to us, and we are committed to ensuring your data is secure.
        </p>

        <h2>Information We Collect</h2>
        <ul>
          <li>
            <strong>Personal Information:</strong> This may include your name,
            contact details, or other information you provide directly to us.
          </li>
          <li>
            <strong>Usage Data:</strong> We may collect information about how
            you use the app, such as features accessed, time spent, and device
            details.
          </li>
        </ul>

        <h2>How We Use Your Information</h2>
        <p>The information collected is used for the following purposes:</p>
        <ul>
          <li>To provide and maintain the functionality of the app.</li>
          <li>To improve our services and enhance user experience.</li>
          <li>
            To comply with legal obligations or respond to lawful requests.
          </li>
        </ul>

        <h2>Third-Party Services</h2>
        <p>
          We may use third-party services for analytics, notifications, or other
          functionalities. These services have their own privacy policies and
          handle data according to their terms.
        </p>

        <h2>Your Rights</h2>
        <p>
          You have the right to access, modify, or delete your personal data. To
          exercise these rights, please contact us using the information below.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about our privacy policy, please
          contact us at:
        </p>
        <p>
          <strong>Email:</strong>{" "}
          <a href="mailto:tausif3008@gmail.com">tausif3008@gmail.com</a> |{" "}
          <a href="mailto:mahakumbh25@gmail.com">mahakumbh25@gmail.com</a>
        </p>
        <p>
          <strong>Address:</strong> Kumbh Mela Adhikari, Triveni Bhawan,
          Prayagraj, Uttar Pradesh
        </p>

        <p>
          Thank you for using the Khoya Paya MahaKumbh25. Your trust is
          important to us.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
