import { Button, Image, Pagination, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { getData } from "../Fetch/Axios";
import URLS from "../Fetch/URLS";
import { truncateString } from "../utils/commonFunctions";
import { langingPage } from "../utils/dictionary";
import { EditOutlined } from "@ant-design/icons";

const CommonSearch = ({
  title,
  imageBaseUrl,
  background,
  columns,
  uri,
  reactBaseUrl,
  loading,
  scroll,
  extraParams,
  form,
  actionFun,
  idLikeParams,
  setIsModalVisible,
  visible = true,
  setTotalCount = () => {},
  totalDocsInput,
  isFoundHandover,
  canEdit,
}) => {
  const navigate = useNavigate();
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  const [details, setDetails] = useState({
    list: [],
    loading: false,
    pageLength: 0,
    totalRecords: totalDocsInput || 0,
    page: 0,
  });

  const params = useParams();

  const getDetails = async (extraParams) => {
    setDetails((prev) => {
      return { ...prev, loading: true };
    });

    let url = URLS[uri];
    if (idLikeParams) {
      url = url + "/" + idLikeParams;
    }

    url = url + "?";

    if (params.page) {
      url = url + "&page=" + params.page.split("=")[1];
    }

    if (params.limit) {
      url = url + "&limit=" + params.limit.split("=")[1];
    }

    if (params.query) {
      url = url + params.query.split("query=")[1];
    }

    const rawDetails = await getData(url + isFoundHandover);

    if (rawDetails) {
      const details = rawDetails?.docs.map((el, index) => {
        let row = {
          key: el.name + index,
          ...el,
        };

        if (actionFun) {
          row.action = actionFun(row);
        }
        return row;
      });

      setTotalCount(rawDetails.pagination.totalDocs);

      setDetails(() => {
        return {
          list: details,
          loading: false,
          limit: rawDetails.pagination.limit,
          page: rawDetails.pagination.page,
          totalDocs: rawDetails.pagination.totalDocs,
        };
      });
    }
  };

  const handlePageChange = (pageNumber, recs) => {
    if (isNaN(pageNumber)) {
      pageNumber = 1;
    }

    let path = "/" + reactBaseUrl + "/page=" + pageNumber + "/limit=" + recs;

    if (extraParams) {
      path = path + "/query=" + extraParams;
    }

    navigate(path);
  };

  useEffect(() => {
    getDetails();

    if (params.query) {
      const query = params.query;

      const fields = query.split("&");

      const formFields = {};

      for (let index = 1; index < fields.length; index++) {
        const spl = fields[index].split("=");

        if (spl.length === 2) {
          formFields[spl[0]] = spl[1];
        }
      }
      if (form) form.setFieldsValue(formFields);
    }
  }, [params, idLikeParams, form]);

  useEffect(() => {
    if (extraParams) {
      let url = "/" + reactBaseUrl;

      if (params.page) {
        url = url + "/page=" + params.page.split("=")[1];
      } else {
        url = url + "/page=1";
      }

      if (params.limit) {
        url = url + "/limit=" + params.limit.split("=")[1];
      } else {
        url = url + "/limit=25";
      }

      url = url + "/query=" + extraParams;

      navigate(url);
    }
  }, [extraParams]);

  return (
    <div className=" p-1 my-3 w-full">
      <div className="border p-2 mb-3">
        <div className={`p-1 text-center bg-${background} font-semibold mb-2 `}>
          {title}
        </div>
        <Spin spinning={details.loading}>
          <div
            className="overflow-scroll w-full border"
            style={{ height: "500px" }}
          >
            <div className="grid w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2">
              {details?.list.map((el, i) => {
                return (
                  <div
                    key={el.photo + i}
                    className="flex w-full gap-0 flex-col text-center justify-center"
                  >
                    {canEdit && (
                      <div className="relative z-2 flex w-full justify-end ">
                        <div className="flex p-2">
                          <Button
                            size="small"
                            className="bg-teal-200"
                            onClick={() => {
                              navigate(
                                (el?.complaint_number.startsWith("L")
                                  ? "/register-lostperson-form/"
                                  : "/register-foundperson-form/") + el._id
                              );
                            }}
                          >
                            <EditOutlined></EditOutlined>
                          </Button>
                        </div>
                      </div>
                    )}

                    <div
                      className={`flex ${
                        canEdit ? "-mt-10" : ""
                      } justify-center items-center h-32 w-full overflow-hidden bg-orange-100`}
                    >
                      <Image
                        width={200}
                        src={imageBaseUrl + el.photo}
                        alt={el.photo}
                      ></Image>
                    </div>

                    <div
                      className="h-14 bg-[#ffd6e7] overflow-clip"
                      onClick={() => {
                        setIsModalVisible(() => {
                          return { ...el };
                        });
                      }}
                    >
                      {truncateString(el.first_name + " " + el.last_name, 15)}
                      <br />
                      {el?.complaint_number}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Spin>

        <div className="mt-2 flex justify-between items-center p-1">
          {
            <div>
              {dict.total[lang]}: {details?.totalDocs}
            </div>
          }
        </div>
      </div>

      {visible && (
        <div className="mr-2 mb-2">
          <Pagination
            align="end"
            showSizeChanger
            showQuickJumper
            current={details?.page || 1}
            total={details?.totalDocs || 1}
            pageSize={details?.limit || 1}
            onChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default CommonSearch;
