import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import CommonDivider from "../commonComponents/CommonDivider";

const TextTranslation = ({
  speechToText,
  onlyText,
  setInputText,
  setLoading,
  loading,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (form) {
      form.setFieldsValue({ text: speechToText.target });
    }
  }, [speechToText.target, form]);

  return (
    <div>
      {!onlyText && (
        <div>
          <CommonDivider label={"Text Translation"}></CommonDivider>
          <div className="grid grid-cols-1 w-full md:grid-cols-2 gap-3 mt-3 mx-auto">
            <div>
              <div className=" flex border-1 rounded  items-between flex-col w-full h-full">
                <div className=" w-full flex justify-between items-center p-2  rounded-t bg-blue-100 shadow-md">
                  <div className="font-bold text-violet-950">
                    Speech To Text
                  </div>
                </div>
                <div className="flex items-center justify-center h-full p-3 bg-blue-50 rounded-b font-semibold text-xl">
                  {speechToText.source || "-"}
                </div>
              </div>

              <div className=" flex border-1 rounded  items-between flex-col w-full h-full">
                <div className=" w-full flex justify-between items-center p-2  rounded-t bg-green-100 shadow-md">
                  <div className="font-bold text-violet-950">
                    Translated Text
                  </div>
                </div>
                <div className="flex items-center justify-center h-full p-3 font-semibold text-xl bg-green-50 rounded-b">
                  {speechToText.target || "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-2 flex border-1 rounded  items-between flex-col w-full h-full">
        <div className=" w-full flex justify-between items-center p-1  rounded-t bg-green-100 shadow-md">
          <div className="font-bold text-violet-950 ">Input/Output Text</div>
        </div>
        <div className="flex w-full items-center justify-center h-full p-2  bg-green-50 rounded-b">
          <Form form={form} noStyle className="w-full mt-4 -mb-6  ">
            <Form.Item name={"text"}>
              <TextArea
                onInput={() =>
                  setLoading(() => {
                    return { loading: false, disableConvert: false };
                  })
                }
              ></TextArea>
            </Form.Item>
            <div className="flex w-full justify-end relative -top-5">
              <Button
                loading={loading.loading}
                disabled={loading.disableConvert}
                className="bg-green-200"
                onClick={() => {
                  setInputText(() => form.getFieldsValue("text").text);
                }}
              >
                Convert
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default TextTranslation;
const { TextArea } = Input;
