import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, message, Upload } from "antd";
import React from "react";
import { fileToBase64 } from "../utils/commonFunctions";
import { langingPage } from "../utils/dictionary";
import { useOutletContext } from "react-router";

const CommonUploader = ({
  label,
  name,
  imgSrc,
  setPhoto,
  noStyle,
  size,
  onlyImage = true,
}) => {
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];
  const beforeUpload = async (file) => {
    if (onlyImage) {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
        return Upload.LIST_IGNORE;
      }
    }

    const base64String = await fileToBase64(file);

    if (base64String) {
      setPhoto(base64String);
    } else {
      console.error("Failed to generate Base64 string.");
    }
    return false; // Prevent auto-upload
  };

  // Handle file removal
  const handleRemove = () => {
    setPhoto(() => false);
  };

  return (
    <Form.Item
      label={label || dict.upload_photo[lang]}
      name={name || "photo"}
      className="w-full"
      valuePropName="filelist"
      getValueFromEvent={(e) => (Array.isArray(e) ? e : e.fileList)}
      noStyle={noStyle}
    >
      <div>
        <Upload
          size="small"
          accept=".jpg, .jpeg, .png, .pdf"
          beforeUpload={beforeUpload}
          onRemove={handleRemove}
          listType="picture"
          maxCount={1}
        >
          <Button size={size} icon={<UploadOutlined />}>
            {dict.upload_photo[lang]}
          </Button>
        </Upload>

        {imgSrc && (
          <div className="mt-2 ">
            <img className="w-32 p-1 border" src={imgSrc} alt="user" />
          </div>
        )}
      </div>
    </Form.Item>
  );
};

export default CommonUploader;
