import React, { useRef, useEffect, useState } from "react";
import { Button } from "antd";
import { BsArrowRepeat } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";
import "./CaptureImage.css";
import CommonUploader from "../../commonComponents/CommonUploader";
import WebcamCapture from "../../commonComponents/WebcamCapture";
import ImageCompressor from "../../commonComponents/ImageCompressor";
import { langingPage } from "../../utils/dictionary";
import { useOutletContext } from "react-router";

const CaptureImage = ({
  setImageSrcMain,
  image,
  setImage,
  isUploader = true,
}) => {
  const camera = useRef(null);
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [hideCam, setHideCam] = useState(false);
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  useEffect(() => {
    return () => {
      const nodeToRemove = document.getElementById("react-camera");
      if (nodeToRemove) {
        nodeToRemove.remove();
      }

      setImage(true);
      setHideCam(true);
    };
  }, []);

  const [captureOrUpload, setCaptureOrUpload] = useState(false);

  return (
    <div className=" flex m-auto flex-col justify-start items-center p-1 w-full ">
      <div className="flex  flex-col justify-start h-full w-fit p-2">
        <div className="flex flex-col justify-center">
          {!captureOrUpload && (
            <Button
              className="w-fit m-auto"
              onClick={() => setCaptureOrUpload("capture")}
            >
              {dict.capture_image[lang]}
            </Button>
          )}

          {captureOrUpload === "capture" && (
            <div className="">
              <WebcamCapture
                setImage={setImage}
                image={image}
                setImageSrcMain={setImageSrcMain}
              ></WebcamCapture>
            </div>
          )}
        </div>

        {!captureOrUpload && isUploader && (
          <div className="flex flex-col justify-center items-center relative">
            <div className="text-center m-2"> {dict.or[lang]}</div>
            <div className="flex justify-center">
              <ImageCompressor
                setImageSrcMain={setImageSrcMain}
              ></ImageCompressor>
            </div>
          </div>
        )}

        <Button
          className="w-fit m-auto bg-green-300"
          hidden={numberOfCameras <= 1}
          onClick={() => {
            camera.current.switchCamera();
          }}
        >
          Switch
        </Button>
      </div>
    </div>
  );
};

export default CaptureImage;
