import { Modal, Table } from "antd";
import React, { useEffect, useState } from "react";

const ViewReports = ({
  series,
  docs,
  dateRange,
  isModalOpen,
  setIsModalOpen,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getFilteredData = (data) => {
      const final = {};

      data.forEach((el) => {
        if (!final[el.createdAt]) {
          final[el.createdAt] = [el];
        } else {
          final[el.createdAt].push(el);
        }
      });

      setData(() => final);
    };

    if (docs) {
      if (series === "s1") {
        getFilteredData(docs.total_lost);
      } else if (series === "s2") {
        getFilteredData(docs.total_found);
      } else if (series === "s3") {
        getFilteredData(docs.total_reunion);
      } else if (series === "s4") {
        getFilteredData(docs.total_pending);
      }
    }
  }, [docs, series]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      width={1000}
      title={
        series === "s1"
          ? "Total Lost"
          : series === "s2"
          ? "Total Found"
          : series === "s3"
          ? "Total Reunion"
          : "Total Pending"
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <LostAndFoundTable data={data}></LostAndFoundTable>
    </Modal>
  );
};

export default ViewReports;

const LostAndFoundTable = ({ data }) => {
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return new Intl.DateTimeFormat("en-US", {
      weekday: "long",
      day: "numeric",
      month: "short",
    }).format(date);
  };

  const tableData = Object.keys(data).map((date) => ({
    key: date, // Ensure the key matches the rowKey
    date: formatDate(date), // Format the date
    count: data[date].length, // Count of records
    records: data[date],
  }));

  // Define columns for the main table
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Total Records",
      dataIndex: "count",
      key: "count",
    },
  ];

  const expandedRowRender = (records) => {
    const expandedColumns = [
      {
        title: "Ticket Number",
        dataIndex: "complaint_number",
        key: "complaint_number",
      },
      {
        title: "First Name",
        dataIndex: "first_name",
        key: "name",
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        key: "name",
      },
      {
        title: "Gender",
        dataIndex: "gender",
        key: "gender",
      },
      {
        title: "Age",
        dataIndex: "age",
        key: "age",
      },
      {
        title: "Sector",
        dataIndex: "center_id",
        key: "sector",
        render: (val) => {
          return val?.sector_id?.name || "-";
        },
      },
    ];

    return (
      <Table columns={expandedColumns} dataSource={records} rowKey="_id" />
    );
  };

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      scroll={{ x: 700, y: 400 }}
      bordered
      expandable={{
        expandedRowRender: (record) => expandedRowRender(record.records),
        rowExpandable: () => true,
      }}
      rowKey="key"
    />
  );
};
