import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";
import CommonDivider from "../commonComponents/CommonDivider";
import CommonSearchForm from "../commonComponents/CommonSearchForm";
import { Button, Form, Image, Select, Table } from "antd";
import { postData } from "../Fetch/Axios";
import URLS from "../Fetch/URLS";
import CommonTable from "../commonComponents/CommonTable";
import AudioConverter from "./AudioPlayer";
import { langingPage } from "../utils/dictionary";

const getLangs = (rows) => {
  const langs = {};

  for (const el of rows) {
    if (el.language_spoken) langs[el._id] = el.language_spoken;
  }
  return langs;
};

const langCode = {
  Hindi: "hi-IN",
  English: "en-IN",
  Bengali: "bn-IN",
  Gujarati: "gu-IN",
  Kannada: "kn-IN",
  Malayalam: "ml-IN",
  Marathi: "mr-IN",
  Punjabi: "pa-IN",
  Tamil: "ta-IN",
  Telugu: "te-IN",
};

const Announcement = ({ permissions }) => {
  const [searchQuery, setSearchQuery] = useState("&");
  const [loading, setLoading] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState(["hi-IN"]);
  const [selectedGender, setSelectedGender] = useState("FEMALE");

  const [form] = Form.useForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState({
    keys: [],
    langs: {},
  });

  const [selectedLostRowKeys, setSelectedLostRowKeys] = useState({
    keys: [],
    langs: {},
  });

  const [selectedFoundRowKeys, setSelectedFoundRowKeys] = useState({
    keys: [],
    langs: {},
  });

  const [audio, setAudio] = useState([]);
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  const gender = [
    { label: dict.male[lang], value: "MALE" },
    { label: dict.female[lang], value: "FEMALE" },
  ];

  const languages = [
    { label: dict.hindi[lang], value: "hi-IN" },
    { label: dict.english[lang], value: "en-IN" },
    { label: dict.bengali[lang], value: "bn-IN" },
    { label: dict.gujarati[lang], value: "gu-IN" },
    { label: dict.kannada[lang], value: "kn-IN" },
    { label: dict.malayalam[lang], value: "ml-IN" },
    { label: dict.marathi[lang], value: "mr-IN" },
    { label: dict.punjabi[lang], value: "pa-IN" },
    { label: dict.tamil[lang], value: "ta-IN" },
    { label: dict.telugu[lang], value: "te-IN" },
  ];

  useEffect(() => {
    if (selectedFoundRowKeys.keys.length || selectedLostRowKeys.keys.length) {
      const finalLang = (langs) => {
        const filteredLangs = {};

        for (const el in langs) {
          if (!selectedLanguages.includes(langs[el])) {
            filteredLangs[el] = langCode[langs[el]];
          }
        }

        return filteredLangs;
      };

      setSelectedRowKeys(() => {
        return {
          keys: [...selectedFoundRowKeys.keys, ...selectedLostRowKeys.keys],
          langs: finalLang({
            ...selectedFoundRowKeys.langs,
            ...selectedLostRowKeys.langs,
          }),
        };
      });
    }
  }, [selectedFoundRowKeys, selectedLostRowKeys]);

  const announceFunction = async () => {
    setLoading(true);

    const announced = await postData(
      {
        ids: selectedRowKeys.keys,
        langs: selectedLanguages,
        defLangs: selectedRowKeys.langs,
        gender: selectedGender,
      },
      URLS.announce
    );

    if (announced) {
      let finalDocs = [];

      for (const element of announced.docs) {
        finalDocs = [...finalDocs, ...element];
      }

      setAudio(() => finalDocs);
      setLoading(false);
    }
  };

  const lostRowSelection = {
    selectedLostRowKeys,
    onChange: (selectedKeys, selectedRows) => {
      const langs = getLangs(selectedRows);

      setSelectedLostRowKeys((prev) => {
        return { keys: selectedKeys, langs };
      });
    },
  };

  const foundRowSelection = {
    selectedFoundRowKeys,
    onChange: (selectedKeys, selectedRows) => {
      const langs = getLangs(selectedRows);

      setSelectedFoundRowKeys((prev) => {
        return { keys: selectedKeys, langs };
      });
    },
  };

  const handleChange = (value) => {
    setSelectedLanguages(() => value);
  };

  const columns = [
    {
      title: dict.first_name[lang],
      dataIndex: "first_name",
      key: "name",
    },
    {
      title: dict.last_name[lang],
      dataIndex: "last_name",
      key: "last_name",
    },

    {
      title: dict.age[lang],
      dataIndex: "age",
      key: "age",
    },
    {
      title: dict.spoken_language[lang],
      dataIndex: "language_spoken",
      key: "language_spoken",
      width: 150,
      render: (val) => {
        return val || "-";
      },
    },
    {
      title: dict.sector[lang],
      dataIndex: "center_id",
      key: "sector",
      render: (val) => {
        return val?.sector_id?.name || "-";
      },
    },
  ];

  const [canProceed, setCanProceed] = useState(false);

  const params = useParams();
  useEffect(() => {
    if (params.query) {
      setCanProceed(true);
    }
  }, []);

  const [maxCount, setMaxCount] = useState({ lost: 0, found: 0 });

  return (
    <div>
      <CommonDivider label={dict.announcement[lang]}></CommonDivider>
      <CommonSearchForm
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        form={form}
        fields={[
          { name: "first_name", label: dict.first_name[lang] },
          { name: "last_name", label: dict.last_name[lang] },
        ]}
      ></CommonSearchForm>

      <div className="flex w-full justify-between items-center gap-2">
        <div className="flex gap-2 items-center">
          <Select
            mode="multiple"
            value={selectedLanguages}
            placeholder={dict.select_language[lang]}
            options={languages}
            onChange={handleChange}
            className="min-w-40"
            style={{ minHeight: "32px" }}
          />

          <Select
            placeholder="Select Gender"
            options={gender}
            value={selectedGender}
            className="w-24"
            onChange={(val) => {
              setSelectedGender(val);
            }}
          />

          <Button
            disabled={
              !(selectedLanguages.length && selectedRowKeys.keys.length)
            }
            loading={loading}
            className={`${
              selectedLanguages.length && selectedRowKeys.keys.length
                ? "bg-green-300"
                : "bg-gray-200"
            } rounded-none`}
            onClick={announceFunction}
          >
            {dict.add_to_queue[lang]}
          </Button>
        </div>

        <AudioConverter audio={audio}></AudioConverter>
      </div>
      {
        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-3 h-full flex-wrap">
          <div>
            <div className="p-1 flex  text-lg font-semibold">
              {dict.lost_persons[lang]}
            </div>
            <style>
              {`
            .ant-table-body {
                min-height: 400px !important;
              }
            `}
            </style>

            <CommonTable
              uri="announceLost"
              reactBaseUrl="announcement"
              columns={columns}
              extraParams={
                searchQuery +
                "fields=first_name,last_name,center_id,gender,age,language_spoken&"
              }
              form={form}
              scroll={{ x: 700, y: 400 }}
              pagination={false}
              rowSelection={lostRowSelection}
              setTotalCount={(val) => {
                setMaxCount((prev) => {
                  return { ...prev, lost: val };
                });
              }}
            />
          </div>

          <div>
            <div className="p-1 flex text-lg font-semibold">
              {dict.found_persons[lang]}
            </div>

            <CommonTable
              uri="announceFound"
              reactBaseUrl="announcement"
              columns={columns}
              extraParams={
                searchQuery
                  ? searchQuery +
                    "fields=first_name,last_name,center_id,gender,age,language_spoken&"
                  : searchQuery
              }
              form={form}
              conditions={!(form && 1)}
              scroll={{ x: 700, y: 400 }}
              rowSelection={foundRowSelection}
              setTotalCount={(val) => {
                setMaxCount((prev) => {
                  return { ...prev, found: val };
                });
              }}
              totalDocsInput={
                maxCount.lost > maxCount.found ? maxCount.lost : maxCount.found
              }
            />
          </div>
        </div>
      }
    </div>
  );
};

export default Announcement;
