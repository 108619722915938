// slices/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";
const roleSlice = createSlice({
  name: "roleSlice",
  initialState: {
    roleUpdateEl: null,
  },
  reducers: {
    setUpdateRoleEl: (state, action) => {
      state.roleUpdateEl = action.payload;
    },
  },
});

export const { setUpdateRoleEl } = roleSlice.actions;
export default roleSlice.reducer;
