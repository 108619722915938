import { Button } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { langingPage } from "../utils/dictionary";
import { useOutletContext } from "react-router";

const AudioPlayer = ({ audioBuffers }) => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const audioRef = useRef(null);
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = 0.85;
    }
  }, [audioUrl]);

  // Function to convert buffer data to Blob URL
  const getAudioUrl = (buffer) => {
    const uint8Array = new Uint8Array(buffer.data);
    const blob = new Blob([uint8Array], { type: "audio/mpeg" });
    return URL.createObjectURL(blob);
  };

  // Load the current track when the index changes
  useEffect(() => {
    if (audioBuffers[currentTrackIndex]) {
      const url = getAudioUrl(audioBuffers[currentTrackIndex]);
      setAudioUrl(url);

      // Cleanup the URL when the track changes
      return () => URL.revokeObjectURL(url);
    }
  }, [currentTrackIndex, audioBuffers]);

  // Play the current track automatically if isPlaying is true
  useEffect(() => {
    if (isPlaying && audioRef.current) {
      audioRef.current.play();
    }
  }, [audioUrl, isPlaying]);

  // Play or pause the audio
  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  // Handle when the audio ends
  const handleEnded = () => {
    if (currentTrackIndex < audioBuffers.length - 1) {
      setCurrentTrackIndex((prevIndex) => prevIndex + 1);
    } else {
      setCurrentTrackIndex(0);

      setIsPlaying(false);
    }
  };

  return (
    <div>
      {audioUrl && (
        <audio
          ref={audioRef}
          src={audioUrl}
          onEnded={handleEnded}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          autoPlay
        />
      )}
      <Button onClick={handlePlayPause} disabled={!audioUrl}>
        {isPlaying ? dict.pause[lang] : dict.play[lang]}
      </Button>
    </div>
  );
};

export default function AudioConverter({ audio }) {
  const [audioBuffers, setAudioBuffers] = useState([]);

  useEffect(() => {
    if (audio) {
      const buffers = [];
      for (const iterator of audio) {
        buffers.push(iterator.audio);
      }
      setAudioBuffers(() => buffers);
    }
  }, [audio]);

  return (
    <div>
      <AudioPlayer audioBuffers={audioBuffers} />
    </div>
  );
}
