import { RotateLeftOutlined, RotateRightOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React, { useState, useEffect } from "react";
import Cropper from "react-easy-crop";

const ImageCropper = ({ base64String, setImageSrc, setIsModalOpen, open }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(4 / 3); // Default aspect ratio
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = base64String;
    img.onload = () => {
      const { width, height } = img;
      const calculatedAspectRatio = width / height;
      setAspectRatio(calculatedAspectRatio);
    };
  }, [base64String]);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const rotateLeft = () => {
    setRotation((prevRotation) => (prevRotation - 10) % 360);
  };

  // Function to rotate right by 10 degrees
  const rotateRight = () => {
    setRotation((prevRotation) => (prevRotation + 10) % 360);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getCroppedImage = async () => {
    const image = new Image();
    image.src = base64String;

    return new Promise((resolve) => {
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const { width, height } = croppedAreaPixels;
        canvas.width = width;
        canvas.height = height;

        // Draw the cropped area onto the canvas
        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          width,
          height
        );

        // Convert canvas to base64 image
        const base64Image = canvas.toDataURL("image/jpeg");
        resolve(base64Image);
      };
    });
  };

  const handleCrop = async () => {
    const croppedImage = await getCroppedImage();
    setImageSrc(croppedImage);
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        title="Basic Modal"
        open={open}
        onOk={handleCancel}
        onCancel={handleCancel}
        width={800}
      >
        <div style={{ position: "relative", width: "100%", height: "390px" }}>
          <Cropper
            image={base64String}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            zoomWithScroll={true}
            minZoom={1}
            maxZoom={5}
            restrictPosition={false}
          />
          {/* Zoom Slider */}
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1000,
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              padding: "10px",
              borderRadius: "10px",
              color: "#fff",
            }}
          >
            <input
              type="range"
              min={1}
              max={5}
              step={0.1}
              value={zoom}
              onChange={(e) => setZoom(Number(e.target.value))}
              style={{ width: "200px" }}
            />

            <div
              className="flex gap-2 justify-center items-center"
              style={{
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <div>
                <button
                  onClick={rotateLeft}
                  style={{
                    padding: "1px 3px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  <RotateLeftOutlined className="text-2xl"></RotateLeftOutlined>
                </button>
              </div>
              <div>
                <button
                  onClick={rotateRight}
                  style={{
                    padding: "1px 3px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  <RotateRightOutlined className="text-2xl" />
                </button>
              </div>
              <div>
                <button
                  onClick={handleCrop}
                  style={{
                    padding: "2px 4px",
                    backgroundColor: "#28a745",
                    color: "#fff",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Get Cropped Image
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ImageCropper;
