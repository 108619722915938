import React from "react";
import URLS from "../../Fetch/URLS";
import FaceDetectionResult from "./FaceDetectionResult";
import { useState } from "react";
import { Button } from "antd";

const FaceDetectionOptions = ({
  image,
  purpose = "found",
  baseUrl = URLS.found_photo_base_url,
}) => {
  const [isLux, setIsLux] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <div className="flex gap-2 my-2 justify-center">
        <Button
          disabled={loading}
          size="small"
          className={isLux ? "bg-orange-300" : "bg-gray-300"}
          onClick={() => {
            setIsLux(true);
          }}
        >
          Luxand
        </Button>
        <Button
          disabled={loading}
          size="small"
          className={!isLux ? "bg-orange-300" : "bg-gray-300"}
          onClick={() => {
            setIsLux(false);
          }}
        >
          MAXF
        </Button>{" "}
      </div>
      <FaceDetectionResult
        image={image}
        purpose={purpose}
        baseUrl={baseUrl}
        isLux={isLux}
        setLoading={setLoading}
        loading={loading}
      ></FaceDetectionResult>
    </div>
  );
};

export default FaceDetectionOptions;
