// slices/index.js
import { combineReducers } from "@reduxjs/toolkit";
import employeeSlice from "./../user/User/employeeSlice";
import centerSlice from "../setting/center/centerSlice";
import searchFoundSlice from "./../search/found_person/searchFoundSlice";
import searchLostSlice from "./../search/lost_person/searchLostSlice";
import roleSlice from "./../user/registerRole/roleSlice";

const rootReducer = combineReducers({
  roleSlice: roleSlice,
  employeeSlice: employeeSlice,
  centerSlice: centerSlice,
  searchFoundSlice: searchFoundSlice,
  searchLostSlice: searchLostSlice,
});

export default rootReducer;
