import React from "react";
import { langingPage } from "../../utils/dictionary";
import { Form, Radio } from "antd";

const AdditionalInfo = ({ lang }) => {
  let dict = langingPage;

  return (
    <div className="">
      <h2 className="text-base font-bold" style={{ marginTop: "-15px" }}>
        {dict.additional_info[lang]}
      </h2>
      <div className="mt-3">
        <Form.Item
          name="receipt_lang"
          label={
            <div className="font-semibold">{dict.select_language[lang]}</div>
          }
        >
          <Radio.Group>
            <Radio value="en">English</Radio>
            <Radio value="hi">हिंदी</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
    </div>
  );
};

export default AdditionalInfo;
