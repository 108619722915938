// slices/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";
const searchFoundSlice = createSlice({
  name: "searchFoundSlice",
  initialState: {
    searchFoundUpdateEl: null,
  },
  reducers: {
    setUpdateSearchFoundEl: (state, action) => {
      state.searchFoundUpdateEl = action.payload;
    },
  },
});

export const { setUpdateSearchFoundEl } = searchFoundSlice.actions;
export default searchFoundSlice.reducer;
