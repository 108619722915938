import { Button, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";

function getLocalDateString(dateString) {
  const date = new Date(dateString);
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return localDate.toISOString().split("T")[0];
}

const ComparisonReport = ({ setIsModalOpen, isModalOpen, data }) => {
  const [finalData, setFinalData] = useState({
    total_lost: {},
    total_found: {},
    total_reunion: {},
    total_pending: {},
  });

  const [title, setTitle] = useState("total_lost");

  useEffect(() => {
    if (data?.length && data[0].total_lost) {
      const finalData = {
        total_lost: {},
        total_found: {},
        total_reunion: {},
        total_pending: {},
      };
      for (const el of data) {
        if (el?.total_lost?.length && el?.total_lost[0]) {
          const date = getLocalDateString(el.total_lost[0].createdAt);

          if (date) {
            if (finalData.total_lost[date]) {
              finalData.total_lost[date] = [
                ...finalData.total_lost[date],
                ...el.total_lost,
              ];
            } else {
              finalData.total_lost[date] = [el.total_lost];
            }
          }
        }

        if (el?.total_found?.length && el?.total_found[0]) {
          const date = getLocalDateString(el.total_found[0].createdAt);

          if (date) {
            if (finalData.total_found[date]) {
              finalData.total_found[date] = [
                ...finalData.total_found[date],
                ...el.total_found,
              ];
            } else {
              finalData.total_found[date] = [el.total_found];
            }
          }
        }
        if (el?.total_reunion?.length && el?.total_reunion[0]) {
          const date = getLocalDateString(el.total_reunion[0].createdAt);

          if (date) {
            if (finalData.total_reunion[date]) {
              finalData.total_reunion[date] = [
                ...finalData.total_reunion[date],
                ...el.total_reunion,
              ];
            } else {
              finalData.total_reunion[date] = [el.total_reunion];
            }
          }
        }
        if (el?.pending_cases?.length && el?.pending_cases[0]) {
          const date = getLocalDateString(el.pending_cases[0].createdAt);

          if (date) {
            if (finalData.total_pending[date]) {
              finalData.total_pending[date] = [
                ...finalData.total_pending[date],
                ...el.pending_cases,
              ];
            } else {
              finalData.total_pending[date] = [el.pending_cases];
            }
          }
        }
      }
      setFinalData(() => finalData);
    }
  }, [data]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <Modal
        width={1000}
        title={"Comparison Report"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <div className="flex gap-2 my-2">
          <Button
            className={
              title === "total_lost"
                ? "bg-orange-400 text-violet-950 font-semibold"
                : "bg-gray-300 text-black font-semibold"
            }
            onClick={() => setTitle(() => "total_lost")}
          >
            Total Lost
          </Button>
          <Button
            className={
              title === "total_found"
                ? "bg-orange-400 text-violet-950 font-semibold"
                : "bg-gray-300 text-black font-semibold"
            }
            onClick={() => setTitle(() => "total_found")}
          >
            Total Found
          </Button>
          <Button
            className={
              title === "total_reunion"
                ? "bg-orange-400 text-violet-950 font-semibold"
                : "bg-gray-300 text-black font-semibold"
            }
            onClick={() => setTitle(() => "total_reunion")}
          >
            Total Reunion
          </Button>
          <Button
            className={
              title === "total_pending"
                ? "bg-orange-400 text-violet-950 font-semibold"
                : "bg-gray-300 text-black font-semibold"
            }
            onClick={() => setTitle(() => "total_pending")}
          >
            Total Pending
          </Button>
        </div>
        <LostAndFoundTable data={finalData[title]}></LostAndFoundTable>
      </Modal>
    </div>
  );
};

const LostAndFoundTable = ({ data }) => {
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return new Intl.DateTimeFormat("en-US", {
      weekday: "long",
      day: "numeric",
      month: "short",
    }).format(date);
  };

  const tableData = Object.keys(data).map((date) => ({
    key: date,
    date: formatDate(date),
    count: data[date][0].length,
    records: data[date][0],
  }));

  // Define columns for the main table
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Total Records",
      dataIndex: "count",
      key: "count",
    },
  ];

  const expandedRowRender = (records) => {
    const expandedColumns = [
      {
        title: "Ticket Number",
        dataIndex: "complaint_number",
        key: "complaint_number",
      },
      {
        title: "First Name",
        dataIndex: "first_name",
        key: "name",
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        key: "name",
      },
      {
        title: "Gender",
        dataIndex: "gender",
        key: "gender",
      },
      {
        title: "Age",
        dataIndex: "age",
        key: "age",
      },
      {
        title: "Sector",
        dataIndex: "center_id",
        key: "sector",
        render: (val) => {
          return val?.sector_id?.name || "-";
        },
      },
    ];

    return (
      <Table columns={expandedColumns} dataSource={records} rowKey="_id" />
    );
  };

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      scroll={{ x: 700, y: 400 }}
      bordered
      expandable={{
        expandedRowRender: (record) => expandedRowRender(record.records),
        rowExpandable: () => true,
      }}
      rowKey="key"
    />
  );
};

export default ComparisonReport;
