import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useOutletContext } from "react-router";
import { langingPage } from "../utils/dictionary";
import { formatDate } from "../utils/commonFunctions";

const LostFoundCasesLineChart = ({ details }) => {
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  const options = {
    chart: {
      id: "lost-found-line-chart",
      toolbar: {
        show: false,
        tools: {
          download: false, // Disable the download button
        },
      },
    },
    xaxis: {
      categories: details?.categories.map((el) => formatDate(el)) || [
        "2024-11-01",
        "2024-11-02",
        "2024-11-03",
      ],
      title: {
        text: "Date",
      },
    },
    yaxis: {
      title: {
        text: dict.case_count[lang],
      },
    },
    stroke: {
      width: 2,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -15,
    },

    title: {
      display: true,
      text: dict.last_three_days_comparison[lang],
    },
  };

  const [series, setSeries] = useState([
    {
      name: "Lost Cases",
      data: [3, 5, 8],
    },
    {
      name: "Found Cases",
      data: [4, 4, 2],
    },
    {
      name: "Reunite Cases",
      data: [7, 5, 1],
    },
    {
      name: "Unresolved Cases",
      data: [1, 4, 6],
    },
  ]);

  useEffect(() => {
    if (details?.results) {
      const series = [
        {
          name: dict.lost_cases[lang],
          data: [3, 5, 8],
        },
        {
          name: dict.found_cases[lang],
          data: [4, 4, 2],
        },
        {
          name: dict.reunite_cases[lang],
          data: [7, 5, 1],
        },
        {
          name: dict.unresolved_cases[lang],
          data: [1, 4, 6],
        },
      ];

      for (let index = 0; index < details?.results.length; index++) {
        series[0].data[index] = Object.values(details?.results[index])[0];

        series[1].data[index] = series[1].data[index] = Object.values(
          details?.results[index]
        )[1];

        series[2].data[index] = series[2].data[index] = Object.values(
          details?.results[index]
        )[2];

        series[3].data[index] = series[3].data[index] = Object.values(
          details?.results[index]
        )[3];
      }

      setSeries(() => series);
    }
  }, [details, lang]);

  return (
    <div className="w-full shadow-sm py-2 mt-3">
      <Chart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default LostFoundCasesLineChart;
