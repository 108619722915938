import { Form, Image, Input, Select } from "antd";
import React from "react";
import URLS from "../../Fetch/URLS";
import { useOutletContext } from "react-router";
import { DICT, langingPage } from "../../utils/dictionary";

const CommonPersonForm = ({ handleOnInput, details }) => {
  const dict1 = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  return (
    <div className="border p-3 pb-1 mb-2 -mt-5">
      <div className="flex gap-3  ">
        <div className="w-32 max-h-28 justify-center items-center overflow-hidden">
          <Image
            src={
              details
                ? details?.complaint_number.startsWith("F")
                  ? URLS.found_photo_base_url + details?.photo
                  : URLS.lost_photo_base_url + details?.photo
                : URLS.lost_photo_base_url + "dummy.png"
            }
            width={100}
          ></Image>
        </div>
        <div className=" grid w-full grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-2 h-fit">
          <Form.Item
            label={dict1.ticket_number[lang]}
            name="complaint_number"
            rules={[
              {
                required: true,
                message: dict1.please_enter_ticket_number[lang],
              },
            ]}
          >
            <Input
              onInput={handleOnInput}
              size="small"
              placeholder={dict1.enter_ticket_number[lang]}
            />
          </Form.Item>

          <Form.Item label={dict1.center[lang]} name="center">
            <Input
              disabled
              size="small"
              placeholder={dict1.enter_center_number[lang]}
            />
          </Form.Item>

          <Form.Item label={dict1.name[lang]} name="name">
            <Input disabled size="small" placeholder={dict1.enter_name[lang]} />
          </Form.Item>

          <Form.Item label={dict1.gender[lang]} name="gender">
            <Select
              disabled
              size="small"
              placeholder={dict1.select_gender[lang]}
            >
              <Option value="M">Male</Option>
              <Option value="F">Female</Option>
              <Option value="O">Other</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={dict1.complainant_name[lang]}
            name="complainant_name"
          >
            <Input
              disabled
              size="small"
              placeholder={dict1.enter_complainant_name[lang]}
            />
          </Form.Item>

          <Form.Item
            label={dict1.complainant_relation[lang]}
            name="complainant_relation"
          >
            <Input
              disabled
              size="small"
              placeholder={dict1.complainant_relation[lang]}
            />
          </Form.Item>

          <Form.Item
            label={dict1.complainant_phone_number[lang]}
            name="complainant_mob_number"
          >
            <Input
              disabled
              size="small"
              placeholder={dict1.enter_phone_number[lang]}
            />
          </Form.Item>

          <Form.Item label={dict1.address[lang]} name="address">
            <Input disabled size="small" placeholder={dict1.address[lang]} />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default CommonPersonForm;
const { Option } = Select;
