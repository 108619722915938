import React, { useState } from "react";
import lostIcon from "../assets/Dashboard/lostIcon.png";
import foundIcon from "../assets/Dashboard/foundIcon.png";
import reunionIcon from "../assets/Dashboard/reunionIcon.png";
import pendingIcon from "../assets/Dashboard/pendingIcon.png";
import lostBg from "../assets/Dashboard/Total Lost bg.png";
import foundBg from "../assets/Dashboard/TotalFoundbg.png";
import reunionBg from "../assets/Dashboard/TotalReunionBg.png";
import pendingBg from "../assets/Dashboard/TotalPendingCasesBg.png";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  DashOutlined,
} from "@ant-design/icons";
import { langingPage } from "../utils/dictionary";
import { useOutletContext } from "react-router";
import { Button, Modal, Table } from "antd";

const getChange = (number, lang, dict) => {
  return number > 0
    ? dict.percent_higher_than_yesterday[lang]
    : number === 0
    ? dict.percent_no_change[lang]
    : number < 0
    ? dict.percent_lower_than_yesterday[lang]
    : "";
};

const getChangeIcon = (number) => {
  return number > 0 ? (
    <div>
      {number}% <CaretUpOutlined />
    </div>
  ) : number === 0 ? (
    <div>
      {number}% <DashOutlined />
    </div>
  ) : number < 0 ? (
    <div>
      {number}% <CaretDownOutlined />
    </div>
  ) : (
    ""
  );
};

const Statistics = ({ details }) => {
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  const data = [
    {
      title: dict.todays_lost_persons_enrolled[lang],
      icon: lostIcon,
      increment:
        details?.change?.total_lost +
        getChange(details?.change?.total_lost, lang, dict),
      perIcon: getChangeIcon(details?.change?.total_lost),
      count: details?.total_lost || 0,
      textColor: "#20AA70",
      bgImage: lostBg,
    },
    {
      title: dict.todays_found_persons_enrolled[lang],
      icon: foundIcon,
      increment:
        details?.change?.total_found +
        getChange(details?.change?.total_found, lang, dict),
      perIcon: getChangeIcon(details?.change?.total_found),
      count: details?.total_found || 0,
      textColor: "#7152F3",
      bgImage: foundBg,
    },
    {
      title: dict.todays_reunion_completed[lang],
      icon: reunionIcon,
      increment:
        details?.change?.total_reunion +
        getChange(details?.change?.total_reunion, lang, dict),
      perIcon: getChangeIcon(details?.change?.total_reunion),
      count: details?.total_reunion || 0,
      textColor: "#48A7FF",
      bgImage: reunionBg,
    },
    {
      title: (
        <div>
          {dict.todays_pending[lang]}
          <br></br> {dict.cases[lang]}
        </div>
      ),
      icon: pendingIcon,
      increment:
        details?.change?.pending_cases +
        getChange(details?.change?.pending_cases, lang, dict),
      perIcon: getChangeIcon(details?.change?.pending_cases),
      count: details?.pending_cases || 0,
      textColor: "#FF900D",
      bgImage: pendingBg,
    },
  ];

  const [title, setTitle] = useState();
  const [isModelOpen, setIsModelOpen] = useState();

  return (
    <div className="grid gap-3 grid-cols-2 sm:grid-cols-2 md:grid-cols-4 mt-3 ">
      <TodaysRecords
        isModalOpen={isModelOpen}
        setIsModalOpen={setIsModelOpen}
        details={details?.todaysDocs}
        setTitle={setTitle}
        title={title}
        dict={dict}
        lang={lang}
      ></TodaysRecords>
      {data.map((el, index) => {
        return (
          <div
            onClick={() => {
              details?.todaysDocs &&
                Object.keys(details?.todaysDocs)?.length &&
                setIsModelOpen(Object.keys(details?.todaysDocs)[index]);

              details?.todaysDocs &&
                Object.keys(details?.todaysDocs)?.length &&
                setTitle(Object.keys(details?.todaysDocs)[index]);
            }}
            key={el.title + " " + index}
            className="flex justify-center shadow-md cursor-pointer"
          >
            <div
              className="flex  justify-center flex-col gap-2 w-full p-3 font-roboto  rounded bg-no-repeat"
              style={{ backgroundImage: `url(${el.bgImage})` }}
            >
              <div className="flex gap-1 justify-between">
                <div
                  className="text-base md:text-xl font-semibold"
                  style={{ color: el.textColor }}
                >
                  {el.title}
                </div>
                <div
                  className="hidden sm:flex md:hidden lg:flex ml-auto"
                  style={{ width: "45px", height: "35px" }}
                >
                  <img
                    className=""
                    style={{ width: "45px", height: "35px" }}
                    src={el.icon}
                    alt=""
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2 justify-between">
                <div className=" flex flex-col justify-content-between">
                  <div className="text-sm md:text-lg font-semibold">
                    {el.count}
                  </div>
                </div>

                {/* <div className="flex items-center w-full justify-between">
                  <div className="text-sm">{el.increment} </div>
                  <div>{el.perIcon}</div>{" "}
                </div>*/}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const TodaysRecords = ({
  isModalOpen,
  setIsModalOpen,
  details,
  title,
  setTitle,
  lang,
  dict,
}) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: dict.ticket_number[lang],
      dataIndex: "complaint_number",
      key: "complaint_number",
    },
    {
      title: dict.first_name[lang],
      dataIndex: "first_name",
      key: "name",
    },
    {
      title: dict.last_name[lang],
      dataIndex: "last_name",
      key: "name",
    },
    {
      title: dict.gender[lang],
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: dict.age[lang],
      dataIndex: "age",
      key: "age",
    },
    {
      title: dict.sector[lang],
      dataIndex: "center_id",
      key: "sector",
      render: (val) => {
        return val?.sector_id?.name || "-";
      },
    },
  ];
  return (
    <Modal
      width={1000}
      title={dict.todays_statistics[lang]}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <div className="flex gap-2 my-2">
        <Button
          className={
            title === "total_lost"
              ? "bg-orange-400 text-violet-950 font-semibold"
              : "bg-gray-300 text-black font-semibold"
          }
          onClick={() => setTitle(() => "total_lost")}
        >
          {dict.total_lost[lang]}
        </Button>
        <Button
          className={
            title === "total_found"
              ? "bg-orange-400 text-violet-950 font-semibold"
              : "bg-gray-300 text-black font-semibold"
          }
          onClick={() => setTitle(() => "total_found")}
        >
          {dict.total_found[lang]}
        </Button>
        <Button
          className={
            title === "total_reunion"
              ? "bg-orange-400 text-violet-950 font-semibold"
              : "bg-gray-300 text-black font-semibold"
          }
          onClick={() => setTitle(() => "total_reunion")}
        >
          {dict.total_reunion[lang]}
        </Button>
        <Button
          className={
            title === "pending_cases"
              ? "bg-orange-400 text-violet-950 font-semibold"
              : "bg-gray-300 text-black font-semibold"
          }
          onClick={() => setTitle(() => "pending_cases")}
        >
          {dict.total_pending[lang]}
        </Button>
      </div>
      <Table columns={columns} dataSource={details && details[title]}></Table>
    </Modal>
  );
};

export default Statistics;
