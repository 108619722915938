import React, { useState } from "react";
import AudioToConverter from "./AudioToConverter";
import LanguageSelector from "./LanguageSelector";
import CommonDivider from "../commonComponents/CommonDivider";

const Converter = ({ onlyText = true }) => {
  const [sourceLanguage, setSourceLanguage] = useState();
  const [targetLanguage, setTargetLanguage] = useState();
  const [gender, setGender] = useState("female");

  return (
    <div>
      {!onlyText && <CommonDivider label={"Audio Translation"}></CommonDivider>}{" "}
      <LanguageSelector
        setSourceLanguage={setSourceLanguage}
        setTargetLanguage={setTargetLanguage}
        setGender={setGender}
        onlyText={onlyText}
      ></LanguageSelector>
      <AudioToConverter
        sourceLanguage={sourceLanguage}
        targetLanguage={targetLanguage}
        gender={gender}
      ></AudioToConverter>
    </div>
  );
};

export default Converter;
