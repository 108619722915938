import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Circle, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import L from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";

const customIcon = new L.Icon({
  iconUrl: markerIconPng,
  iconSize: [25, 41], // Default size of marker icon
  iconAnchor: [12, 41], // Anchor point for the marker
  popupAnchor: [1, -34],
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  shadowSize: [41, 41],
});

// Include the customIcon as imported
const LiveLocationUpdater = ({ location }) => {
  const map = useMap();

  useEffect(() => {
    if (location) {
      map.setView(location, 18); // Set zoom level
    }
  }, [location, map]);

  return null;
};

const LiveLocationMap = ({ location, setLocation }) => {
  useEffect(() => {
    const updateLocation = (position) => {
      const { latitude, longitude } = position.coords;
      setLocation(() => {
        return { lat: latitude, lng: longitude };
      });
    };

    const locationWatcher = navigator.geolocation.watchPosition(
      updateLocation,
      (error) => console.error("Error fetching location", error),
      { enableHighAccuracy: true }
    );

    return () => navigator.geolocation.clearWatch(locationWatcher);
  }, []);

  return (
    <MapContainer
      center={location || [25.4358, 81.8463]}
      zoom={18}
      style={{ height: "40vh", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href='https://osm.org/copyright'>OpenStreetMap</a> contributors"
      />

      {location && <LiveLocationUpdater location={location} />}

      {location && (
        <>
          <Marker position={location} icon={customIcon} />
          <Circle center={location} radius={30} />
        </>
      )}
    </MapContainer>
  );
};

export default LiveLocationMap;
