import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { patchData, postData } from "../../Fetch/Axios";
import URLS from "../../Fetch/URLS";
import CommonDivider from "../../commonComponents/CommonDivider";
import { useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { getSingleData } from "../../utils/commonFunctions";
import { langingPage } from "../../utils/dictionary";

const RoleRegistrationForm = () => {
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [updateDetails, setUpdateDetails] = useState();
  const { id } = useParams();

  const Selector = useSelector((state) => state.roleSlice?.roleUpdateEl);

  useEffect(() => {
    if (Selector) {
      setUpdateDetails(Selector);
    }
  }, [Selector]);

  const data = async () => {
    const data = await getSingleData(URLS.role + "/" + id);
    setUpdateDetails(data);
  };

  useEffect(() => {
    if (Selector) {
      setUpdateDetails(Selector);
    } else if (id) {
      data();
    }
  }, [Selector, form]);

  useEffect(() => {
    if (updateDetails) {
      form.setFieldsValue(updateDetails);
    }
  }, [updateDetails, form]);

  const onFinish = async (values) => {
    setLoading(true);

    let response;

    if (id) response = await patchData(values, URLS.role + "/" + id);
    else response = await postData(values, URLS.role);

    if (response?.status === "success") {
      setUpdateDetails(null);

      if (id) {
        navigate("/role");
      }

      form.resetFields();
    }

    setLoading(false);
  };

  return (
    <div className="mx-auto p-6 bg-white shadow-md rounded-lg mt-3 w-full">
      <CommonDivider
        backCompo={{ path: "/role" }}
        label={
          <div className="text-d9 text-2xl  flex items-end justify-between w-full">
            <div className="font-bold">
              {updateDetails ? "Update Role Details" : dict.create_role[lang]}
            </div>
            <div className="text-xs">{dict.all_mandatory_fields[lang]}</div>
          </div>
        }
      ></CommonDivider>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ company_name: "KASH IT SOLUTION" }}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5">
          <Form.Item
            label={<div className="font-semibold">{dict.role_name[lang]}</div>}
            name="name"
            rules={[
              {
                required: true,
                message: dict.please_enter_role_name[lang],
              },
              // {
              //   validator: nameLikeFieldsValidator(
              //     "Please enter a valid name!"
              //   ),
              // },
            ]}
            className="mb-4"
          >
            <Input
              placeholder={dict.role_name[lang]}
              className="rounded-none "
            />
          </Form.Item>
        </div>
        <div className="flex justify-end mb-0">
          <Form.Item noStyle>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="w-fit rounded-none bg-5c"
            >
              {updateDetails ? "Update" : dict.register[lang]}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default RoleRegistrationForm;
