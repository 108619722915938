import React from "react";
import phone from "../assets/Dashboard/phone.png";
import phoneIcon from "../assets/Dashboard/phone-alt.png";
import { langingPage } from "../utils/dictionary";
import { useOutletContext } from "react-router";

const HelplineNumber = ({ background, text }) => {
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  return (
    <div
      className={`flex flex-col sm:flex-row text-center mt-3 items-center border-2 border-orange-500 w-fit p-3 m-auto ${background}`}
    >
      <span className={`mr-8 text-xl font-semibold ${text} `}>
        {dict.prayagraj_kumbh_mela[lang]} <span className="font-bold">{dict.helpline_no[lang]}</span>
      </span>
      <div className={`flex items-center  ${text || "bg-orange-400"} h-12`}>
        <div className="flex justify-center items-center">
          <img className="h-10 absolute" src={phone} alt="" />
          <img className="h-6 absolute" src={phoneIcon} alt="" />
        </div>
        <div className="ml-5 p-2 text-xl font-semibold text-white">
          01334-224 457
        </div>
      </div>
    </div>
  );
};

export default HelplineNumber;
