import React from "react";
import CommonUploader from "../../commonComponents/CommonUploader";
import SourceRadio from "./HandoverSource";
import { DatePicker, Form, Input, Select } from "antd";
import {
  disableFutureDates,
  limitLength,
  validateMobileNumber,
} from "../../utils/commonFunctions";
import { DICT, langingPage } from "../../utils/dictionary";
import { useOutletContext } from "react-router";
import CommonSelectOption from "./CommonSelectOption";
import { useState } from "react";

const ChildHandover = ({
  form,
  setImage,
  image,
  setImageSrcMain,
  setDocument,
}) => {
  const dict = DICT;
  const dict1 = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];
  const [type, setType] = useState("Police");
  return (
    <div className="flex gap-3">
      <div className=" mt-3 grid w-full grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-2 h-fit">
        <Form.Item
          label={dict1.select_date[lang]}
          name={"receiver_handover_date"}
          rules={[{ required: true, message: dict1.please_select_date[lang] }]}
        >
          <DatePicker
            size="small"
            style={{ width: "100%" }}
            disabledDate={disableFutureDates}
            placeholder={dict1.select_date[lang]}
          />
        </Form.Item>
        <div className="flex">
          <Form.Item
            label={dict1.handover_to[lang]}
            name={"receiver_handover_to"}
            rules={[{ required: true }]}
          >
            <CommonSelectOption
              form={form}
              name="receiver_handover_to"
              type={type}
              value={type}
              setType={setType}
              placeholder={"Handover to"}
              selectOption={[
                { value: "Police", label: dict1.police[lang] },
                { value: "Bhauguna", label: dict1.bhauguna[lang] },
                { value: "Other", label: dict1.other[lang] },
              ]}
            ></CommonSelectOption>
          </Form.Item>
        </div>
        <Form.Item
          label={dict1.name_concern_person[lang]}
          name={"receiver_name"}
          rules={[
            {
              required: true,
              message: dict1.please_enter_receiver_name[lang],
            },
          ]}
        >
          <Input size="small" placeholder={dict1.enter_name[lang]} />
        </Form.Item>

        {type === "Police" ? (
          <Form.Item
            label={dict1.police_station_name[lang]}
            name={"receiver_police_station_name"}
            rules={[
              { required: true, message: dict1.please_enter_police_station_name[lang] },
            ]}
          >
            <Input size="small" placeholder={dict1.police_station_name[lang]} />
          </Form.Item>
        ) : type === "Other" ? (
          <Form.Item
            label={"Other details (NGO NAME)"}
            name={"receiver_other_details"}
            rules={[{ required: true, message: "Please enter other details" }]}
          >
            <Input size="small" placeholder={"Other Details"} />
          </Form.Item>
        ) : (
          ""
        )}
        <Form.Item
          label={dict1.gender[lang]}
          name={"receiver_gender"}
          rules={[
            { required: true, message: dict1.please_select_gender[lang] },
          ]}
        >
          <Select size="small" placeholder={dict1.select_gender[lang]}>
            <Option value="M">{dict1.male[lang]}</Option>
            <Option value="F">{dict1.female[lang]}</Option>
            <Option value="O">{dict1.other[lang]}</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={dict1.phone_number[lang]}
          name={"receiver_mobile_no"}
          rules={[
            {
              required: "true",
              message: dict1.please_enter_phone_number[lang],
            },
            { validator: validateMobileNumber(dict, lang) },
          ]}
        >
          <Input
            onChange={(e) => {
              limitLength(e, "receiver_mobile_no", form, 10);
            }}
            size="small"
            placeholder={dict1.enter_phone_number[lang]}
          />
        </Form.Item>

        <Form.Item
          label={dict1.address[lang]}
          name={"receiver_address"}
          rules={[
            { required: true, message: dict1.please_enter_address[lang] },
          ]}
        >
          <Input size="small" placeholder={dict1.address[lang]} />
        </Form.Item>

        <div className="col-span-2">
          <CommonUploader
            size={"small"}
            setPhoto={setDocument}
            label={
              <div className="">
                {dict1.supporting_doc_1[lang]}({dict1.along_with_police_witness_details[lang]})
              </div>
            }
            name={"receiver_supporting_doc"}
            onlyImage={false}
          ></CommonUploader>
        </div>

        <Form.Item label={dict1.remark[lang]} name={"receiver_remark"}>
          <Input size="small" placeholder={dict1.remark[lang]} />
        </Form.Item>

        <div className="col-span-5">
          <SourceRadio></SourceRadio>
        </div>
      </div>
    </div>
  );
};

export default ChildHandover;
const { Option } = Select;
