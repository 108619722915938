import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { getData } from "../Fetch/Axios";
import URLS from "../Fetch/URLS";
import dayjs from "dayjs";
import { truncateString } from "../utils/commonFunctions";

const MessageContainer = ({ details }) => {
  const [finalData, setFinalData] = useState([]);

  useEffect(() => {
    if (details) {
      const data = [];

      for (const el in details) {
        details[el].forEach((ele) => {
          data.push({
            name: ele?.first_name,
            location: ele?.lost_place,
            date: ele?.lost_date
              ? dayjs(ele?.lost_date).format("YYYY-MM-DD")
              : ele?.createdAt
              ? dayjs(ele?.createdAt).format("YYYY-MM-DD")
              : "",
            event:
              el === "lost_cases"
                ? "Lost"
                : el === "Found"
                ? "Found"
                : "Reunited",
            image:
              (el === "lost_cases"
                ? URLS.lost_photo_base_url
                : el === "found_cases"
                ? URLS.found_photo_base_url
                : URLS.user_pho_base_url) + ele?.photo,
            color: ele?.isFound || ele?.isHandover === "Yes" ? "green" : "red",
          });
        });
      }

      setFinalData(() => {
        return [...data];
      });
    }
  }, [details]);

  return (
    <div className="mt-3">
      {/* <div className=" text-green-700 font-semibold text-2xl"> */}
      {/* {dict.recent_load_found[lang]}{" "}
      </div>
      <Divider className="bg-green-500 mt-0" /> */}

      <Marquee direction="left">
        {finalData &&
          finalData.map((el, index) => {
            return (
              <div
                key={el.first_name + index}
                className="ml-4 bg-green-100 bg-opacity-40 rounded-t-md  overflow-hidden w-32"
              >
                <div className="rounded-t-md  overflow-hidden h-36">
                  <div className="rounded-t-md  overflow-hidden h-24 flex justify-center items-center">
                    <img
                      src={el.image}
                      className="overflow-hidden  w-34"
                      alt={el.name}
                    />
                  </div>

                  <div className="text-center pb-2 w-full  text-sm font-semibold font-devnagri text-teal-950 bg-blue-400 bg-opacity-50">
                    <div className="px-2">
                      {truncateString(el.name, 10)} <br></br>(
                      <span className="text-red-900">{el.event}</span>)
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Marquee>
    </div>
  );
};

export default MessageContainer;
