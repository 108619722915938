import React from "react";

const ContactUs = () => {
  return (
    <div
      className="contact-details-container"
      style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}
    >
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Contact Us</h1>
      <p style={{ textAlign: "center", marginBottom: "40px" }}>
        If you have any questions or need assistance, feel free to contact us:
      </p>

      <div style={{ maxWidth: "600px", margin: "0 auto", lineHeight: "1.6" }}>
        <h2>Contact Information</h2>
        <p>
          <strong>Phone:</strong> <a href="tel:+919763224602">+91 9763224602</a>
        </p>
        <p>
          <strong>Email:</strong>{" "}
          <a href="mailto:support@kumbhtsmonitoring.com">
            support@kumbhtsmonitoring.com
          </a>
        </p>
        <p>
          <strong>Address:</strong> Kumbh Mela Adhikari, Triveni Bhawan,
          Prayagraj, Uttar Pradesh
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
