import React, { useEffect, useState } from "react";
import { Form, Input, Button, DatePicker, Select, Image, message } from "antd";

import { useOutletContext } from "react-router";

import printJS from "print-js";
import { DICT, langingPage } from "../../utils/dictionary";
import { base64ToFile, smallFieldStyle } from "../../utils/commonFunctions";
import URLS from "../../Fetch/URLS";
import { getData, postData } from "../../Fetch/Axios";
import AudioNotepad from "../../commonComponents/AudioNotepad";
import CommonPersonForm from "./CommonPersonForm";
import Handover from "./Handover";
import PoliceHandover from "./PoliceOutsideForm";
import ChildHandover from "./ChildHandover";
import TracedHandover from "./TracedHandover";
import dayjs from "dayjs";
import AdditionalInfo from "./../../RegisterAll/register_lost/AdditionalInfo";
import PrintModal from "../../commonComponents/PrintModal";

const CommonHandover = ({ type }) => {
  const dict1 = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];
  const [form] = Form.useForm();
  const [pdfUrl, setPdfUrl] = useState();

  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState();
  const [document, setDocument] = useState();

  const [image, setImage] = useState();
  const [imageSrcMain, setImageSrcMain] = useState();

  useEffect(() => {
    form.setFieldsValue({ receiver_handover_date: dayjs(Date.now()) });
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    setPdfUrl();

    if (!values.center) {
      message.error("Incorrect Ticket Number!");
      return;
    }

    if (document) values.receiver_supporting_doc = base64ToFile(document);

    const formData = new FormData();

    for (const key in values) {
      if (values[key] && key.includes("receiver"))
        formData.append(key, values[key]);
    }

    formData.append("complaint_number", values.complaint_number);
    formData.append("type", type);
    formData.append("receipt_lang", values.receipt_lang);

    if (imageSrcMain) {
      formData.append("receiver_photo", base64ToFile(imageSrcMain));
    }

    let response;

    response = await postData(formData, URLS.handover, true, {
      complaint_number: values.complaint_number,
    });

    if (response?.status === "success") {
      setPdfUrl(
        URLS.handover_receipt_base_url + response?.doc?.handover_receipt
      );

      setLoading(false);
      setDetails();
      setImage();
      setImageSrcMain();
      setImageSrc(false);
      form.resetFields();
    } else {
      setLoading(false);
    }
  };

  const handleOnInput = async (e) => {
    let url;
    let val = e.target.value;
    if (val.length >= 4) {
      if (val[0] === "F") {
        url =
          URLS.found_handover_search +
          val +
          "?&isHandover=No&fields=first_name,last_name,center_id,gender,complaint_number,photo,address";
      } else if (val[0] === "L") {
        url =
          URLS.lost_handover_search +
          val +
          "?isFound=false&fields=first_name,last_name,center_id,gender,complaint_number,complainant_relation,complainant_name,complainant_mob_number,address";
      }

      if (url) {
        const rawDetails = await getData(url);
        if (rawDetails?.doc) {
          setDetails(() => {
            return rawDetails.doc;
          });
        } else {
          setDetails();
          form.resetFields([
            "complainant_name",
            "center",
            "name",
            "gender",
            "complainant_mob_number",
          ]);
          message.error(
            "Incorrect Ticket Number or Complaint Already Hand Over!"
          );
        }
      }
    } else {
      form.resetFields([
        "complainant_name",
        "center",
        "name",
        "gender",
        "complainant_mob_number",
      ]);
      setDetails();
    }
  };

  useEffect(() => {
    if (details) {
      const newDetails = {
        name: details?.first_name + " " + details?.last_name,
        gender: details?.gender[0],
        photo: "dummy.png",
        age: details?.age,
        complainant_name: details.complainant_name,
        complainant_relation: details.complainant_relation,
        complainant_mob_number: details.complainant_mob_number,
        address: details.address,
        center: details?.center_id?.name,
      };

      form.setFieldsValue(newDetails);
    }
  }, [details, form]);

  return (
    <div>
      {/* <AudioNotepad top={100}></AudioNotepad> */}
      {smallFieldStyle()}

      {pdfUrl && (
        <PrintModal
          url={pdfUrl}
          message="Handover Status"
          setPdfUrl={setPdfUrl}
        ></PrintModal>
      )}
      <div className="mt-3 ">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{ receipt_lang: "hi" }}
        >
          <div className="">
            <CommonPersonForm
              details={details}
              handleOnInput={handleOnInput}
            ></CommonPersonForm>
          </div>

          {/* Receiver Information */}

          <div className="mt-3 border p-3 pb-1 mb-2">
            {type !== "Telephonic Trace or Self - टेलीफोनिक ट्रेस या स्वयं" ? (
              <div className="text-lg -mt-4 pb-2 font-semibold">
                {dict1.receiver_information[lang]}
              </div>
            ) : (
              <div className="text-lg -mt-4 text-red-900">
                Note: In self handover process print out not generated
              </div>
            )}

            {type === "Handover - सुपुर्दगी" ? (
              <Handover
                form={form}
                setDocument={setDocument}
                setImage={setImage}
                image={image}
                setImageSrcMain={setImageSrcMain}
              ></Handover>
            ) : type ===
              "Outside (Police Handover) - बाहर (पुलिस सुपुर्दगी)" ? (
              <PoliceHandover
                form={form}
                setDocument={setDocument}
                setImage={setImage}
                image={image}
                setImageSrcMain={setImageSrcMain}
              ></PoliceHandover>
            ) : type === "Child Handover - बच्चे की सुपुर्दगी" ||
              type === "Male Handover - पुरुष सुपुर्दगी" ||
              type === "Female Handover - महिला सुपुर्दगी" ? (
              <ChildHandover
                form={form}
                setDocument={setDocument}
                setImage={setImage}
                image={image}
                setImageSrcMain={setImageSrcMain}
              ></ChildHandover>
            ) : type ===
              "Telephonic Trace or Self - टेलीफोनिक ट्रेस या स्वयं" ? (
              <TracedHandover form={form}></TracedHandover>
            ) : (
              ""
            )}
          </div>
          <div className="border p-3 pb-1 mb-2">
            <AdditionalInfo lang={lang}></AdditionalInfo>
          </div>
          <div className="flex justify-end">
            <Button
              size="small"
              type="default"
              htmlType="reset"
              className="rounded-none bg-yellow-500  font-bold py-2 px-4 mr-2"
              onClick={() => {
                form.resetFields();
                setDetails();
              }}
            >
              {dict1.reset[lang]}
            </Button>

            <Button
              size="small"
              type="primary"
              loading={loading}
              htmlType="submit"
              className="bg-green-500 text-white font-bold py-2 px-4 rounded-none"
            >
              {dict1.submit[lang]}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CommonHandover;
