import React, { useEffect } from "react";
import CommonDivider from "../../commonComponents/CommonDivider";
import CommonSearchForm from "../../commonComponents/CommonSearchForm";
import { useState } from "react";
import CommonTable from "../../commonComponents/CommonTable";
import { Form } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router";
import { FaUserEdit } from "react-icons/fa";
import { setUpdateCenterEl } from "./centerSlice";
import { langingPage } from "../../utils/dictionary";

const columnsItems = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: 200,
  },
  {
    title: "Sector",
    dataIndex: "sector_id",
    key: "sector",
    width: 200,
    render: (val) => {
      return val?.name;
    },
  },
  {
    title: "latitude",
    dataIndex: "latlng",
    key: "latitude",
    width: "200",
    render: (val) => {
      return val.lat;
    },
  },
  {
    title: "longitude",
    dataIndex: "latlng",
    key: "longitude",
    width: "200",
    render: (val) => {
      return val.lng;
    },
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    width: 200,
  },
];

const CenterList = ({ permissions }) => {
  const [searchQuery, setSearchQuery] = useState();

  const [columns, setColumns] = useState([...columnsItems]);
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  useEffect(() => {

    const updatedColumns = [
      {
        title: dict.name[lang],
        dataIndex: "name",
        key: "name",
        width: 200,
      },
      {
        title: dict.type[lang],
        dataIndex: "type",
        key: "type",
        width: 200,
      },
      {
        title: dict.sector[lang],
        dataIndex: "sector_id",
        key: "sector",
        width: 200,
        render: (val) => {
          return val?.name;
        },
      },
      {
        title: dict.latitude[lang],
        dataIndex: "latlng",
        key: "latitude",
        width: "200",
        render: (val) => {
          return val.lat;
        },
      },
      {
        title: dict.longitude[lang],
        dataIndex: "latlng",
        key: "longitude",
        width: "200",
        render: (val) => {
          return val.lng;
        },
      },
      {
        title: dict.address[lang],
        dataIndex: "address",
        key: "address",
        width: 200,
      },
    ]

    if (permissions?.update) {
      setColumns((prev) => {
        return [
          ...prev,
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            width: 100,
            fixed: "right",
          },
        ];
      });
    }
    setColumns(updatedColumns);
  }, [permissions, lang]);

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const actionFun = (el) => {
    return (
      <div
        className="border-none border flex w-10 p-1 justify-center items-center"
        onClick={() => {
          delete el["action"];
          dispatch(setUpdateCenterEl(el));
          navigate("/center-registration/" + el._id);
        }}
      >
        <span className="justify-center items-center">
          <FaUserEdit className="text-2xl text-orange-400" />
        </span>
      </div>
    );
  };

  useEffect(() => {
    dispatch(setUpdateCenterEl(null));
  }, []);

  return (
    <div>
      <CommonDivider
        label={dict.center[lang]}
        compo={permissions.create && { path: "/center-registration" }}
      ></CommonDivider>

      <CommonSearchForm
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        form={form}
        fields={[{ name: "name", label: dict.name[lang] }]}
      ></CommonSearchForm>

      <CommonTable
        uri="center"
        reactBaseUrl="center"
        columns={columns}
        extraParams={searchQuery}
        form={form}
        scroll={{ x: 1450, y: 400 }}
        actionFun={actionFun}
      ></CommonTable>
    </div>
  );
};

export default CenterList;
