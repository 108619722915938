import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select } from "antd";
import { patchData, postData } from "../../Fetch/Axios";
import URLS from "../../Fetch/URLS";
import CommonDivider from "../../commonComponents/CommonDivider";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { getSingleData } from "../../utils/commonFunctions";
import LiveTrackingMap from "../../commonComponents/CommonLocationTracker";
import { setUpdateCenterEl } from "./centerSlice";
import CommonFormDropDownMaker from "../../commonComponents/CommonFormDropDownMaker";
import { langingPage } from "../../utils/dictionary";

const CenterRegistrationForm = () => {
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];
  const [form] = Form.useForm();
  const [location, setLocation] = useState([51.505, -0.09]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [updateDetails, setUpdateDetails] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();

  const Selector = useSelector((state) => state.centerSlice?.centerUpdateEl);

  useEffect(() => {
    if (Selector) {
      setUpdateDetails(Selector);
    }
  }, [Selector]);

  const data = async () => {
    const data = await getSingleData(URLS.center + "/" + id);
    setUpdateDetails(data);
  };

  useEffect(() => {
    if (Selector) {
      setUpdateDetails(Selector);
    } else if (id) {
      data();
    }
  }, [Selector, form]);

  useEffect(() => {
    if (updateDetails) {
      form.setFieldsValue(updateDetails);
      form.setFieldsValue({ sector_id: updateDetails?.sector_id?._id });
    }
  }, [updateDetails, form]);

  const onFinish = async (values) => {
    setLoading(true);

    values.latlng = location;

    let response;

    if (id) response = await patchData(values, URLS.center + "/" + id);
    else response = await postData(values, URLS.center);

    if (response?.status === "success") {
      setUpdateDetails(null);

      if (id) {
        navigate("/center");
      }
      dispatch(setUpdateCenterEl(null));

      form.resetFields();
    }

    setLoading(false);
  };

  return (
    <div className="mx-auto p-6 bg-white shadow-md rounded-lg mt-3 w-full">
      <CommonDivider
        backCompo={{ path: "/center" }}
        label={
          <div className="text-d9 text-2xl  flex items-end justify-between w-full">
            <div className="font-bold">
              {updateDetails ? "Update Center Details" : dict.center_registration[lang]}
            </div>
            <div className="text-xs">{dict.all_mandatory_fields[lang]}</div>
          </div>
        }
      ></CommonDivider>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ company_name: "KASH IT SOLUTION" }}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5">
          <Form.Item
            label={<div className="font-semibold">{dict.center_name[lang]}</div>}
            name="name"
            rules={[
              {
                required: true,
                message: dict.please_enter_center_name[lang],
              },
            ]}
            className="mb-4"
          >
            <Input placeholder={dict.center_name[lang]} className="rounded-none " />
          </Form.Item>
          <Form.Item
            label={<div className="font-semibold">{dict.center_type[lang]}</div>}
            name="type"
            rules={[
              {
                required: true,
                message: dict.please_enter_center_type[lang],
              },
            ]}
            className="mb-4"
          >
            <Select
              placeholder={dict.select_center_type[lang]}
              options={[
                { value: "Model Center", label: dict.model_center[lang] },
                { value: "Satellite Center", label: dict.satellite_center[lang] },
              ]}
            ></Select>
          </Form.Item>
          <CommonFormDropDownMaker
            selectLabel={dict.select_sector[lang]}
            required={true}
            RequiredMessage={dict.please_select_center[lang]}
            placeholder={dict.select_sector[lang]}
            responseIdName={"_id"}
            selectName={"sector_id"}
            responseLabelName={"name"}
            uri={"sector"}
            sortOptions={"?sort=createdAt&limit=10000"}
          ></CommonFormDropDownMaker>
          {/* <Form.Item
            label={<div className="font-semibold">Circle</div>}
            name="circle"
            rules={[
              {
                required: true,
                message: "Please enter center circle!",
              },
            ]}
            className="mb-4"
          >
            <Input placeholder="Center circle" className="rounded-none " /> */}
          {/* </Form.Item> */}
          <Form.Item
            label={<div className="font-semibold">{dict.address[lang]}</div>}
            name="address"
            rules={[
              {
                required: true,
                message: dict.please_enter_address_name[lang],
              },
            ]}
            className="mb-4"
          >
            <Input placeholder={dict.center_address[lang]} className="rounded-none " />
          </Form.Item>{" "}
        </div>
        <div className="mb-3">
          <LiveTrackingMap
            location={location}
            setLocation={setLocation}
          ></LiveTrackingMap>
        </div>
        <div className="flex justify-end mb-0">
          <Form.Item noStyle>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="w-fit rounded-none bg-5c"
            >
              {updateDetails ? "Update" : dict.register[lang]}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default CenterRegistrationForm;
