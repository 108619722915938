import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import pause from "../assets/Images/audioRecoder/pause.png";
import recorder from "../assets/Images/audioRecoder/recorder.png";
import bhashini from "../Fetch/BhashiniCredentials";

const OnlyRecorder = () => {
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [uploading, setUploading] = useState(false);
  const [base64Audio, setBase64Audio] = useState("");
  const mediaRecorderRef = useRef(null);
  const mediaStreamRef = useRef(null); // Add a ref to store the media stream
  const [detectedLanguage, setDetectedLanguage] = useState();

  const [lngs, setLngs] = useState({});

  useEffect(() => {
    const getLng = async () => {
      const payload = {
        masterNames: ["languages", "inferenceEndpoints"],
      };
      const response = await fetch(
        "https://meity-auth.ulcacontrib.org/ulca/mdms/v0/fetch-master/bulk",
        {
          method: "POST",
          headers: {
            Authorization: `gbes7KiCpI3uqHoYH5OY_TPgPVZ67lsDXT65ZTUFKJ752fvm_xROvoac9yuUdw2V`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await response.json();
      const lngs = {};
      for (const el of result.data.languages) {
        if (el.active) {
          lngs[el.code] = el.label;
        }
      }
      setLngs(() => lngs);
    };

    getLng();
  }, []);
  const audioChunksRef = useRef([]);

  const handleStopRecording = () => {
    mediaRecorderRef.current.stop();
    mediaStreamRef.current.getTracks().forEach((track) => track.stop());

    setRecording(false);
  };
  const handleStartRecording = async () => {
    setAudioURL(false);
    setUploading(false);

    // Request user audio input
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    mediaRecorderRef.current = new MediaRecorder(stream, {
      mimeType: "audio/webm",
    });

    mediaStreamRef.current = stream;

    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorderRef.current.onstop = async () => {
      // Create a Blob from recorded audio chunks
      const audioBlob = new Blob(audioChunksRef.current, {
        type: "audio/webm",
      });

      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioURL(audioUrl);
      audioChunksRef.current = [];

      // Convert the Blob to a WAV file and Base64 encode it
      const wavBlob = await convertToWav(audioBlob);
      const base64Audio = await blobToBase64(wavBlob);
      setBase64Audio(base64Audio);
    };

    mediaRecorderRef.current.start();
    setRecording(true);
  };

  // Helper function to convert WebM Blob to WAV Blob
  const convertToWav = async (webmBlob) => {
    const arrayBuffer = await webmBlob.arrayBuffer();
    const audioContext = new AudioContext();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    // Create WAV file header
    const wavBuffer = audioBufferToWav(audioBuffer);
    return new Blob([wavBuffer], { type: "audio/wav" });
  };

  // Helper function to generate WAV file buffer
  const audioBufferToWav = (buffer) => {
    const numOfChannels = buffer.numberOfChannels;
    const length = buffer.length * numOfChannels * 2 + 44;
    const wavBuffer = new ArrayBuffer(length);
    const view = new DataView(wavBuffer);

    // RIFF chunk descriptor
    writeString(view, 0, "RIFF");
    view.setUint32(4, 36 + buffer.length * numOfChannels * 2, true);
    writeString(view, 8, "WAVE");

    // FMT sub-chunk
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numOfChannels, true);
    view.setUint32(24, buffer.sampleRate, true);
    view.setUint32(28, buffer.sampleRate * numOfChannels * 2, true);
    view.setUint16(32, numOfChannels * 2, true);
    view.setUint16(34, 16, true);

    // Data sub-chunk
    writeString(view, 36, "data");
    view.setUint32(40, buffer.length * numOfChannels * 2, true);

    // Write interleaved PCM samples
    let offset = 44;
    for (let i = 0; i < buffer.length; i++) {
      for (let channel = 0; channel < numOfChannels; channel++) {
        const sample = buffer.getChannelData(channel)[i] * 32767;
        view.setInt16(offset, sample, true);
        offset += 2;
      }
    }

    return wavBuffer;
  };

  // Helper function to write strings to DataView
  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(",")[1]; // Extract Base64 string
        resolve(base64Data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleUploadAudio = async () => {
    if (!base64Audio) return;

    setUploading(true);

    const payload = {
      modelId: "66cf53e58cfc565ee0d1a8e2",
      task: "audio-lang-detection",
      audioContent: base64Audio,
      source: "mixed",
      Authorization: bhashini.apiKey,
      ulcaApiKey: bhashini.udyatKey,
      userID: bhashini.userId,
    };

    try {
      const response = await fetch(
        "https://meity-auth.ulcacontrib.org/ulca/apis/v0/model/compute",
        {
          method: "POST",
          headers: {
            Authorization: bhashini.apiKey,
            ulcaApiKey: bhashini.udyatKey,
            userID: bhashini.userId,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await response.json();

      if (result?.output[0]?.langPrediction[0]?.langCode.length < 3) {
        setDetectedLanguage(
          () => lngs[result?.output[0]?.langPrediction[0]?.langCode]
        );
      } else {
        setDetectedLanguage(() => "---");
      }
    } catch (err) {
      console.error("Upload Error:", err);
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    return () => {
      // Stop the media recorder if it is active
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current.stop();
      }
      // Stop all media stream tracks
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  return (
    <div>
      <div className=" flex border-1 rounded  items-between flex-col  bg-blue-50">
        <div className=" w-full flex justify-between items-center p-2  rounded-t bg-blue-100 shadow-md">
          <div className="font-bold text-violet-950 text-sm">Hello</div>
        </div>
        <div className="flex flex-col items-center justify-center pt-3 ">
          {recording ? (
            <div className="flex flex-col items-center justify-center ">
              <Button
                className="border-none bg-transparent h-10"
                onClick={handleStopRecording}
              >
                <img src={pause} className="h-full" alt="" />
              </Button>

              <div className=" flex items-center justify-center ">
                <div className="relative">
                  <div className=" absolute top-0 left-0 w-4 h-4 bg-red-600 rounded-full animate-ping"></div>
                  <div className="w-4 h-4 bg-red-600 rounded-full"></div>
                </div>
                <div className="ml-2 text-xs text-red-500 font-semibold">
                  Recording...
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full flex justify-center flex-col items-center gap-1">
              <Button
                className="border-none bg-transparent h-10"
                onClick={handleStartRecording}
              >
                <img src={recorder} className="h-full" alt="" />
              </Button>
            </div>
          )}
        </div>
        <div>
          <div className="flex mt-1 justify-center w-full h-full items-center p-1  mb-2">
            <button
              onClick={handleUploadAudio}
              className={`px-2 py-1 text-xs text-white rounded-full ${
                audioURL ? "bg-green-500" : "bg-gray-400"
              } hover:${audioURL ? "bg-green-600" : "bg-gray-500"} transition`}
              disabled={uploading && audioURL}
            >
              {uploading ? "Detecting..." : "Detect"}
            </button>
          </div>
        </div>
      </div>

      <div className=" flex border-1 rounded  items-between flex-col border-violet-200 mt-2 bg-green-50">
        <div className=" w-full flex justify-between items-center p-2  rounded-t bg-green-100 shadow-md">
          <div className="font-bold text-violet-950 text-sm">Output</div>
        </div>
        <div className="flex flex-col items-center justify-center pt-1 ">
          <div className="flex flex-col items-center justify-center ">
            <Button
              className="border-none bg-transparent h-10"
              onClick={handleStopRecording}
            >
              {detectedLanguage || "---"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlyRecorder;
