import React, { useState } from "react";
import { Modal, Button } from "antd";
import { handlePrint } from "../utils/commonFunctions";

const PrintModal = ({ url, message, setPdfUrl }) => {
  const [isModalOpen, setIsModalOpen] = useState(url);

  // Function to handle modal open/close
  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => {
    setPdfUrl();
    setIsModalOpen(false);
  };

  return (
    <div>
      {/* Button to open the modal */}
      <Button type="primary" onClick={showModal}>
        Show Modal
      </Button>
      <Modal
        title={message}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Close
          </Button>,
          <Button key="print" type="primary" onClick={() => handlePrint(url)}>
            Print PDF
          </Button>,
        ]}
      >
        <p>Registered Successfully!</p>
      </Modal>
    </div>
  );
};

export default PrintModal;
