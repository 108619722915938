const nameLikeFieldsValidator = (message) => {
  return (_, value) => {
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!nameRegex.test(value)) {
      return Promise.reject(new Error(message));
    }
    return Promise.resolve();
  };
};

const numberValidator = (message, number = 100) => {
  return (_, value) => {
    if (value < 0 || value > number) {
      return Promise.reject(new Error(message));
    }
    return Promise.resolve();
  };
};

const dateValidator = (message) => {
  return (_, value) => {
    if (new Date(value) > new Date()) {
      return Promise.reject(new Error(message));
    }
    return Promise.resolve();
  };
};

export { nameLikeFieldsValidator, numberValidator, dateValidator };
