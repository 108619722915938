import { Route } from "react-router";
import CenterList from "../setting/center/CenterList";
import Role from "../user/registerRole/Role";
import Permissions from "../user/permissions/Permissions";
import FoundForm from "../RegisterAll/register_lost/FoundForm";
import LostForm from "../RegisterAll/register_lost/LostForm";
import UserList from "../user/User/UserList";
import UserRegistrationForm from "../user/User/UserRegistrationForm";
import RoleRegistrationForm from "../user/registerRole/RoleRegistrationForm";
import CenterRegistrationForm from "../setting/center/CenterRegistrationForm";
import Handover from "../Handover/Handover";
import HandoverForm from "../Handover/HandoverForm";
import Dashboard from "../dashboard/Dashboard";
import CallingStatus from "../Handover/CallinStatus/CallingStatus";
import Announcement from "../announcement/Announcement";
import TVConfiguration from "../tvCinfuguration/TVConfiguration";
import CommonReport from "../Reports/CommonReport";

const allRoutes = {
  tv_configuration: [
    {
      path: "tv-configuration",
      element: TVConfiguration,
      mainPurpose: "read",
    },
  ],
  calling_status: [
    {
      path: "calling-status/:page?/:limit?/:query?",
      element: CallingStatus,
      mainPurpose: "read",
    },
  ],
  announcement: [
    {
      path: "announcement/:page?/:limit?/:query?",
      mainPurpose: "read",
      element: Announcement,
    },
  ],
  dashboard: [
    {
      path: "dashboard/:page?/:limit?/:query?",
      element: Dashboard,
      mainPurpose: "read",
    },
  ],

  users: [
    {
      path: "user/:page?/:limit?/:query?",
      element: UserList,
      mainPurpose: "read",
    },
    {
      path: "user-registration",
      element: UserRegistrationForm,
      mainPurpose: "create",
    },
    {
      path: "user-registration/:id",
      element: UserRegistrationForm,
      mainPurpose: "update",
    },
  ],
  center: [
    {
      path: "center/:page?/:limit?/:query?",
      element: CenterList,
      mainPurpose: "read",
    },
    {
      path: "center-registration",
      element: CenterRegistrationForm,
      mainPurpose: "create",
    },
    {
      path: "center-registration/:id",
      element: CenterRegistrationForm,
      mainPurpose: "update",
    },
  ],
  role: [
    {
      path: "role/:page?/:limit?/:query?",
      element: Role,
      mainPurpose: "read",
    },
    {
      path: "role-registration",
      element: RoleRegistrationForm,
      mainPurpose: "create",
    },
    {
      path: "role-registration/:id",
      element: RoleRegistrationForm,
      mainPurpose: "update",
    },
  ],
  found_person: [
    {
      path: "register-foundperson-form/:id?",
      element: FoundForm,
      mainPurpose: "create",
    },
  ],
  lost_person: [
    {
      path: "register-lostperson-form/:id?",
      element: LostForm,
      mainPurpose: "create",
    },
  ],
  permissions: [
    {
      path: "manage-permissions",
      element: Permissions,
      mainPurpose: "read",
    },
  ],
  handover: [
    {
      path: "handover-receipt",
      element: HandoverForm,
      mainPurpose: "create",
    },
    {
      path: "handover/:page?/:limit?/:query?",
      element: Handover,
      mainPurpose: "read",
    },
  ],
  reports: [
    {
      path: "reports",
      element: CommonReport,
      mainPurpose: "read",
    },
  ],
};

const getRoutes = (permissions) => {
  const routes = [];

  for (const el of permissions) {
    if (allRoutes[el?.module_.name]) {
      const routeInfo = allRoutes[el?.module_.name];

      for (const element of routeInfo) {
        if (el[element.mainPurpose]) {
          routes.push(
            <Route
              key={element.path}
              path={element.path}
              element={
                <element.element
                  permissions={{
                    create: el.create,
                    read: el.read,
                    update: el.update,
                    delete: el.delete,
                  }}
                />
              }
            ></Route>
          );
        }
      }
    }
  }

  return routes;
};

export default getRoutes;
