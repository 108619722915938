// import React, { useEffect, useState } from "react";
// import CommonDivider from "../../commonComponents/CommonDivider";
// import CommonTable from "../../commonComponents/CommonTable";
// import { Button, Checkbox, Form, Input, message, Select, Table } from "antd";
// import CommonSelect from "../../commonComponents/CommonSelect";
// import CommonFormDropDownMaker from "../../commonComponents/CommonFormDropDownMaker";
// import { getData, patchData, putData } from "../../Fetch/Axios";
// import URLS from "../../Fetch/URLS";
// import { langingPage } from "../../utils/dictionary";
// import { useOutletContext } from "react-router";
// const { Option } = Select;

// const columns = [
//   {
//     title: "Module",
//     dataIndex: "module_",
//     key: "module_",
//   },
//   {
//     title: "Create",
//     dataIndex: "create",
//     key: "create",
//   },
//   {
//     title: "Read",
//     dataIndex: "read",
//     key: "read",
//   },
//   {
//     title: "Update",
//     dataIndex: "update",
//     key: "update",
//   },
//   {
//     title: "Delete",
//     dataIndex: "delete",
//     key: "delete",
//   },
// ];

// const Permissions = ({ userRoleUserReg, userRegistration, permissions }) => {
//   const dict = langingPage;
//   const outlet = useOutletContext();
//   const lang = outlet[1];
//   const [dataSource, setDataSource] = useState([]);
//   const [form] = Form.useForm();
//   const [userRole, setUserRole] = useState(null);
//   const [loading, setLoading] = useState(false);

//   const [finalUpdatedObject, setFinalUpdatedObject] = useState({});

//   const [makeFool, setMakeFool] = useState({ fool: true, field: null });

//   const handelChange = (val) => {
//     setMakeFool((prev) => {
//       return {
//         fool: !prev.fool,
//         field: val.field,
//       };
//     });
//   };

//   useEffect(() => {
//     if (userRoleUserReg) {
//       setUserRole(() => userRoleUserReg);
//     }
//   }, [userRoleUserReg]);

//   useEffect(() => {
//     if (makeFool.field) {
//       const id = makeFool.field.split("-");
//       const val = form.getFieldValue(makeFool.field);

//       setFinalUpdatedObject((prev) => {
//         return {
//           ...prev,
//           [id[0]]: {
//             ...finalUpdatedObject[id[0]],
//             [id[1]]: val,
//           },
//         };
//       });
//     }
//   }, [makeFool, form]);

//   const getDetails = async () => {
//     const data = await getData(URLS.rolePermissions + "/" + userRole);

//     if (data?.doc?.permissions) {
//       setDataSource();

//       const finalData = data?.doc?.permissions.map((el, index) => {
//         return {
//           key: el?._id,
//           module_: (
//             <div className="capitalize" key={el._id}>
//               {el?.module_?.name?.split("_").join(" ")}
//             </div>
//           ),
//           create: (
//             <div
//               className="flex gap-2 justify-start items-center"
//               key={el._id + "create"}
//             >
//               <Form.Item
//                 valuePropName="checked"
//                 name={el?._id + "-create"}
//                 initialValue={el.create} // Checkbox default value
//                 noStyle
//               >
//                 <Checkbox
//                   disabled={
//                     userRoleUserReg || el._id === "6720b6be9731e8e98238a5ed"
//                   }
//                   placeholder=""
//                   onChange={(val) => {
//                     handelChange({
//                       val: val.target.checked,
//                       field: el?._id + "-create",
//                     });
//                   }}
//                 />
//               </Form.Item>
//             </div>
//           ),
//           read: (
//             <div
//               className="flex gap-2 justify-start items-center"
//               key={el._id + "read"}
//             >
//               <Form.Item
//                 valuePropName="checked"
//                 initialValue={el.read} // Checkbox default value
//                 name={el?._id + "-read"}
//                 noStyle
//               >
//                 <Checkbox
//                   disabled={
//                     userRoleUserReg || el._id === "6720b6be9731e8e98238a5ed"
//                   }
//                   placeholder=""
//                   onChange={(val) => {
//                     handelChange({
//                       val: val.target.checked,
//                       field: el?._id + "-read",
//                     });
//                   }}
//                 />
//               </Form.Item>
//             </div>
//           ),
//           update: (
//             <div
//               className="flex gap-2 justify-start items-center"
//               key={el._id + "update"}
//             >
//               <Form.Item
//                 valuePropName="checked"
//                 initialValue={el.update} // Checkbox default value
//                 name={el?._id + "-update"}
//                 noStyle
//               >
//                 <Checkbox
//                   disabled={
//                     userRoleUserReg || el._id === "6720b6be9731e8e98238a5ed"
//                   }
//                   placeholder=""
//                   onChange={(val) => {
//                     handelChange({
//                       val: val.target.checked,
//                       field: el?._id + "-update",
//                     });
//                   }}
//                 />
//               </Form.Item>
//             </div>
//           ),
//           delete: (
//             <div
//               className="flex gap-2 justify-start items-center"
//               key={el._id + "delete"}
//             >
//               <Form.Item
//                 valuePropName="checked"
//                 initialValue={el.delete} // Checkbox default value
//                 name={el?._id + "-delete"}
//                 noStyle
//               >
//                 <Checkbox
//                   disabled={
//                     userRoleUserReg || el._id === "6720b6be9731e8e98238a5ed"
//                   }
//                   placeholder=""
//                   onChange={(val) => {
//                     handelChange({
//                       val: val.target.checked,
//                       field: el?._id + "-delete",
//                     });
//                   }}
//                 />
//               </Form.Item>
//             </div>
//           ),
//         };
//       });

//       setDataSource(() => finalData);
//     }
//   };

//   useEffect(() => {
//     if (userRole) {
//       getDetails();
//     }
//   }, [userRole]);

//   const onFinish = async () => {
//     setLoading(true);
//     const finalData = [];

//     for (const key in finalUpdatedObject) {
//       finalData.push({ id: key, updates: finalUpdatedObject[key] });
//     }

//     if (finalData.length) {
//       const data = await patchData(finalData, URLS.updatePermissions);
//       if (data) {
//         setLoading(false);
//       }
//     } else {
//       message.info(dict.please_update_permissions[lang]);
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="">
//       {!userRegistration && (
//         <div>
//           <CommonDivider label={dict.manage_permissions[lang]}></CommonDivider>
//           <div className="flex w-full justify-end mb-3">
//             <div className="w-40">
//               <CommonFormDropDownMaker
//                 uri={"role"}
//                 placeholder={dict.select_role[lang]}
//                 noStyle={true}
//                 responseIdName={"_id"}
//                 responseLabelName={"name"}
//                 setValue={setUserRole}
//               ></CommonFormDropDownMaker>
//             </div>
//           </div>
//         </div>
//       )}

//       <Form form={form} onFinish={onFinish}>
//         <Table
//           columns={columns}
//           dataSource={dataSource}
//           bordered
//           loading={loading}
//           pagination={false}
//         ></Table>
//         {!userRegistration && permissions.update && (
//           <div className="mt-3 flex w-full justify-end">
//             <Form.Item>
//               <Button
//                 loading={loading}
//                 type="primary"
//                 htmlType="submit"
//                 className="bg-orange-300"
//               >
//                 {dict.update[lang]}
//               </Button>
//             </Form.Item>
//           </div>
//         )}
//       </Form>
//     </div>
//   );
// };

// export default Permissions;

import React, { useEffect, useState } from "react";
import CommonDivider from "../../commonComponents/CommonDivider";
import CommonTable from "../../commonComponents/CommonTable";
import { Button, Checkbox, Form, Input, message, Select, Table } from "antd";
import CommonSelect from "../../commonComponents/CommonSelect";
import CommonFormDropDownMaker from "../../commonComponents/CommonFormDropDownMaker";
import { getData, patchData, putData } from "../../Fetch/Axios";
import URLS from "../../Fetch/URLS";
import { langingPage } from "../../utils/dictionary";
import { useOutletContext } from "react-router";
const { Option } = Select;

const Permissions = ({ userRoleUserReg, userRegistration, permissions }) => {
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(false);

  const [finalUpdatedObject, setFinalUpdatedObject] = useState({});

  const [makeFool, setMakeFool] = useState({ fool: true, field: null });

  const [columns, setColumns] = useState([
    {
      title: "Module",
      dataIndex: "module_",
      key: "module_",
    },
    {
      title: "Create",
      dataIndex: "create",
      key: "create",
    },
    {
      title: "Read",
      dataIndex: "read",
      key: "read",
    },
    {
      title: "Update",
      dataIndex: "update",
      key: "update",
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
    },
  ]);

  useEffect(() => {
    const updatedColumns = [
      {
        title: dict.module[lang],
        dataIndex: "module_",
        key: "module_",
      },
      {
        title: dict.create[lang],
        dataIndex: "create",
        key: "create",
      },
      {
        title: dict.read[lang],
        dataIndex: "read",
        key: "read",
      },
      {
        title: dict.update[lang],
        dataIndex: "update",
        key: "update",
      },
      {
        title: dict.delete[lang],
        dataIndex: "delete",
        key: "delete",
      },
    ];
    setColumns(updatedColumns);
  }, [permissions, lang]);

  const handelChange = (val) => {
    setMakeFool((prev) => {
      return {
        fool: !prev.fool,
        field: val.field,
      };
    });
  };

  useEffect(() => {
    if (userRoleUserReg) {
      setUserRole(() => userRoleUserReg);
    }
  }, [userRoleUserReg]);

  useEffect(() => {
    if (makeFool.field) {
      const id = makeFool.field.split("-");
      const val = form.getFieldValue(makeFool.field);

      setFinalUpdatedObject((prev) => {
        return {
          ...prev,
          [id[0]]: {
            ...finalUpdatedObject[id[0]],
            [id[1]]: val,
          },
        };
      });
    }
  }, [makeFool, form]);

  const getDetails = async () => {
    const data = await getData(URLS.rolePermissions + "/" + userRole);

    if (data?.doc?.permissions) {
      setDataSource();

      const finalData = data?.doc?.permissions.map((el, index) => {
        return {
          key: el?._id,
          module_: (
            <div className="capitalize" key={el._id}>
              {el?.module_?.name?.split("_").join(" ")}
            </div>
          ),
          create: (
            <div
              className="flex gap-2 justify-start items-center"
              key={el._id + "create"}
            >
              <Form.Item
                valuePropName="checked"
                name={el?._id + "-create"}
                initialValue={el.create} // Checkbox default value
                noStyle
              >
                <Checkbox
                  disabled={
                    userRoleUserReg || el._id === "6720b6be9731e8e98238a5ed"
                  }
                  placeholder=""
                  onChange={(val) => {
                    handelChange({
                      val: val.target.checked,
                      field: el?._id + "-create",
                    });
                  }}
                />
              </Form.Item>
            </div>
          ),
          read: (
            <div
              className="flex gap-2 justify-start items-center"
              key={el._id + "read"}
            >
              <Form.Item
                valuePropName="checked"
                initialValue={el.read} // Checkbox default value
                name={el?._id + "-read"}
                noStyle
              >
                <Checkbox
                  disabled={
                    userRoleUserReg || el._id === "6720b6be9731e8e98238a5ed"
                  }
                  placeholder=""
                  onChange={(val) => {
                    handelChange({
                      val: val.target.checked,
                      field: el?._id + "-read",
                    });
                  }}
                />
              </Form.Item>
            </div>
          ),
          update: (
            <div
              className="flex gap-2 justify-start items-center"
              key={el._id + "update"}
            >
              <Form.Item
                valuePropName="checked"
                initialValue={el.update} // Checkbox default value
                name={el?._id + "-update"}
                noStyle
              >
                <Checkbox
                  disabled={
                    userRoleUserReg || el._id === "6720b6be9731e8e98238a5ed"
                  }
                  placeholder=""
                  onChange={(val) => {
                    handelChange({
                      val: val.target.checked,
                      field: el?._id + "-update",
                    });
                  }}
                />
              </Form.Item>
            </div>
          ),
          delete: (
            <div
              className="flex gap-2 justify-start items-center"
              key={el._id + "delete"}
            >
              <Form.Item
                valuePropName="checked"
                initialValue={el.delete} // Checkbox default value
                name={el?._id + "-delete"}
                noStyle
              >
                <Checkbox
                  disabled={
                    userRoleUserReg || el._id === "6720b6be9731e8e98238a5ed"
                  }
                  placeholder=""
                  onChange={(val) => {
                    handelChange({
                      val: val.target.checked,
                      field: el?._id + "-delete",
                    });
                  }}
                />
              </Form.Item>
            </div>
          ),
        };
      });

      setDataSource(() => finalData);
    }
  };

  useEffect(() => {
    if (userRole) {
      getDetails();
    }
  }, [userRole]);

  const onFinish = async () => {
    setLoading(true);
    const finalData = [];

    for (const key in finalUpdatedObject) {
      finalData.push({ id: key, updates: finalUpdatedObject[key] });
    }

    if (finalData.length) {
      const data = await patchData(finalData, URLS.updatePermissions);
      if (data) {
        setLoading(false);
      }
    } else {
      message.info(dict.please_update_permissions[lang]);
      setLoading(false);
    }
  };

  return (
    <div className="">
      {!userRegistration && (
        <div>
          <CommonDivider label={dict.manage_permissions[lang]}></CommonDivider>

          <div className="flex w-full justify-end mb-3">
            <div className="w-40">
              <CommonFormDropDownMaker
                uri={"role"}
                placeholder={dict.select_role[lang]}
                noStyle={true}
                responseIdName={"_id"}
                responseLabelName={"name"}
                setValue={setUserRole}
              ></CommonFormDropDownMaker>
            </div>
          </div>
        </div>
      )}

      <Form form={form} onFinish={onFinish}>
        <Table
          columns={columns}
          dataSource={dataSource}
          bordered
          loading={loading}
          pagination={false}
        ></Table>
        {!userRegistration && permissions.update && (
          <div className="mt-3 flex w-full justify-end">
            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="bg-orange-300"
              >
                {dict.update[lang]}
              </Button>
            </Form.Item>
          </div>
        )}
      </Form>
    </div>
  );
};

export default Permissions;
