// slices/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";
const searchLostSlice = createSlice({
  name: "searchLostSlice",
  initialState: {
    searchLostUpdateEl: null,
  },
  reducers: {
    setUpdateSearchLostEl: (state, action) => {
      state.searchLostUpdateEl = action.payload;
    },
  },
});

export const { setUpdateSearchLostEl } = searchLostSlice.actions;
export default searchLostSlice.reducer;
