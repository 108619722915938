import { Form, Select } from "antd";
import React, { useEffect } from "react";
import "./CommonSelect.css";

const CommonSelect = ({
  label,
  options,
  setValue,
  input,
  setLocalSourceLnag,
  isSourceLang = false,
  name,
  initialValues,
}) => {
  const handleChange = (value) => {
    if (input && isSourceLang) {
      setValue({
        sourceLanguage: value,
        targetList: input.target[value],
        serviceModel: input.modelServiceId[value],
      });
    }

    if (input) {
      setLocalSourceLnag(value);
    } else setValue(value);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (options) {
      form.resetFields();
    }
  }, [options, form]);

  return (
    <div>
      <Form form={form} initialValues={initialValues || {}}>
        <Form.Item noStyle name={name || "ant_select"}>
          <Select
            size="small"
            showSearch
            placeholder={label}
            style={{
              width: 160,
            }}
            onChange={handleChange}
            options={options}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default CommonSelect;
