import React, { useState } from "react";

const DeleteAccount = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleDeleteAccount = () => {
    if (isChecked) {
      alert("Account deleted!");
      // Add your account deletion logic here
    }
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <h1>Delete Account</h1>
      <p>Deleting your account is permanent and cannot be undone.</p>
      <div style={{ margin: "20px 0" }}>
        <input
          type="checkbox"
          id="confirm-delete"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="confirm-delete" style={{ marginLeft: "8px" }}>
          I understand the consequences of deleting my account.
        </label>
      </div>
      <button
        onClick={handleDeleteAccount}
        disabled={!isChecked}
        style={{
          backgroundColor: isChecked ? "#007BFF" : "#d6d6d6",
          color: "white",
          border: "none",
          padding: "10px 20px",
          borderRadius: "4px",
          cursor: isChecked ? "pointer" : "not-allowed",
        }}
      >
        Delete Account
      </button>
    </div>
  );
};

export default DeleteAccount;
