import axios from "axios";
import { getData } from "../Fetch/Axios";
import CommonDivider from "./../commonComponents/CommonDivider";
import { Button, DatePicker, Form, message, Select } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { disableFutureDates } from "../utils/commonFunctions";
import URLS from "../Fetch/URLS";
const { RangePicker } = DatePicker;

const getTitle = (val) => {
  return (
    <div className="flex mb-2 font-semibold m-auto text-lg w-full text-center border justify-center items-center">
      {val}
    </div>
  );
};

const downloadLostReport = async (
  startDate,
  endDate,
  setLoading,
  form,
  names,
  reportName,
  disable,
  uri
) => {
  try {
    let url = URLS.base_url + uri;

    if (startDate) {
      url = url + "startDate=" + startDate;
    }

    if (endDate) {
      url = url + "&endDate=" + endDate;
    }

    const response = await axios.get(url, {
      responseType: "blob",
    });

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${reportName}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (response) {
      for (const name of names) {
        form.setFieldsValue({ [name]: null });
      }

      setLoading(false);
      disable(false);
    }
  } catch (error) {
    message.error("Error downloading the report", error);
  }
};

export default function CommonReport() {
  const [loading, setLoading] = useState(false);
  const [isRangeDate, setIsRangeDate] = useState(false);
  const [lostReport, setLostReport] = useState(false);
  const [foundReport, setFoundReport] = useState(false);

  const [form] = Form.useForm();

  return (
    <div>
      <Form form={form}>
        <div>
          <CommonDivider label={"Reports"}></CommonDivider>
          <div className="grid grid-cols-3 gap-3">
            {/* <div className="justify-center items-center border-1 border-violet-700 bg-orange-50 rounded-md p-2 shadow-md ">
              {getTitle("Day Wise Report")}
              <div className="flex gap-2 flex-col  justify-center items-center ">
                <Form.Item noStyle name="singleReport" key={loading}>
                  <DatePicker
                    allowClear={false}
                    onChange={(val) => setIsDate(true)}
                    disabledDate={disableFutureDates}
                  ></DatePicker>
                </Form.Item>
                <Button
                  disabled={!isDate}
                  onClick={() => {
                    setLoading(true);
                    const startDate = form.getFieldsValue("singleReport");

                    const finalStartDate = dayjs(startDate.singleReport).format(
                      "YYYY-MM-DD"
                    );

                    if (finalStartDate) {
                      downloadLostReport(
                        finalStartDate,
                        null,
                        setLoading,
                        form,
                        ["singleReport"],
                        " of Date " + finalStartDate,
                        setIsDate
                      );
                    }
                  }}
                  loading={loading}
                >
                  Download
                </Button>
              </div>
            </div> */}

            <div className="justify-center items-center border-1 border-violet-700 bg-orange-50 rounded-md p-2 shadow-md ">
              {getTitle("Consolidated Report")}

              <div className="flex gap-2 flex-col  justify-center items-center ">
                <Form.Item noStyle name="consolidate">
                  <RangePicker
                    allowClear={false}
                    onChange={(val) => {
                      setIsRangeDate(true);
                    }}
                    disabledDate={disableFutureDates}
                  />
                </Form.Item>

                <Button
                  disabled={!isRangeDate}
                  onClick={() => {
                    setLoading(true);
                    const startDate = form.getFieldsValue("consolidate");

                    const finalStartDate = dayjs(
                      startDate.consolidate[0]
                    ).format("YYYY-MM-DD");

                    const finalEndDate = dayjs(startDate.consolidate[1]).format(
                      "YYYY-MM-DD"
                    );

                    if (finalStartDate && finalEndDate) {
                      downloadLostReport(
                        finalStartDate,
                        finalEndDate,
                        setLoading,
                        form,
                        ["consolidate"],
                        "Consolidate Report from " +
                          finalStartDate +
                          " to " +
                          finalEndDate,
                        setIsRangeDate,
                        URLS.reports
                      );
                    }
                  }}
                  loading={loading}
                >
                  Download
                </Button>
              </div>
            </div>

            <div className="justify-center items-center border-1 border-violet-700 bg-orange-50 rounded-md p-2 shadow-md ">
              {getTitle("Lost Data Report")}

              <div className="flex gap-2 flex-col  justify-center items-center ">
                <Form.Item noStyle name="lostReport">
                  <RangePicker
                    allowClear={false}
                    onChange={(val) => {
                      setLostReport(true);
                    }}
                    disabledDate={disableFutureDates}
                  />
                </Form.Item>

                <Button
                  disabled={!lostReport}
                  onClick={() => {
                    setLoading(true);
                    const startDate = form.getFieldsValue("lostReport");

                    const finalStartDate = dayjs(
                      startDate.lostReport[0]
                    ).format("YYYY-MM-DD");

                    const finalEndDate = dayjs(startDate.lostReport[1]).format(
                      "YYYY-MM-DD"
                    );

                    if (finalStartDate && finalEndDate) {
                      downloadLostReport(
                        finalStartDate,
                        finalEndDate,
                        setLoading,
                        form,
                        ["lostReport"],
                        "Lost Report from " +
                          finalStartDate +
                          " to " +
                          finalEndDate,
                        setLostReport,
                        URLS.lostReports
                      );
                    }
                  }}
                  loading={loading}
                >
                  Download
                </Button>
              </div>
            </div>

            <div className="justify-center items-center border-1 border-violet-700 bg-orange-50 rounded-md p-2 shadow-md ">
              {getTitle("Found Data Report")}

              <div className="flex gap-2 flex-col  justify-center items-center ">
                <Form.Item noStyle name="foundReport">
                  <RangePicker
                    allowClear={false}
                    onChange={(val) => {
                      setFoundReport(true);
                    }}
                    disabledDate={disableFutureDates}
                  />
                </Form.Item>

                <Button
                  disabled={!foundReport}
                  onClick={() => {
                    setLoading(true);
                    const startDate = form.getFieldsValue("foundReport");

                    const finalStartDate = dayjs(
                      startDate.foundReport[0]
                    ).format("YYYY-MM-DD");

                    const finalEndDate = dayjs(startDate.foundReport[1]).format(
                      "YYYY-MM-DD"
                    );

                    if (finalStartDate && finalEndDate) {
                      downloadLostReport(
                        finalStartDate,
                        finalEndDate,
                        setLoading,
                        form,
                        ["foundReport"],
                        "Found Report from " +
                          finalStartDate +
                          " to " +
                          finalEndDate,
                        setFoundReport,
                        URLS.foundReport
                      );
                    }
                  }}
                  loading={loading}
                >
                  Download
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
