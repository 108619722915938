import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { postData } from "../../Fetch/Axios";
import URLS from "../../Fetch/URLS";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const handleOnInput = (id) => {
    const captRes = document.querySelector(id);
    if (captRes) {
      captRes.innerText = "";
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const doSubmit = (id, inputId) => {
    let user_captcha_value = document.getElementById(inputId).value;

    if (validateCaptcha(user_captcha_value, false)) {
      return true;
    } else {
      const captRes = document.querySelector(id);

      if (captRes) {
        captRes.innerText = "Incorrect Captcha";
      }
      return false;
    }
  };

  const onFinish = async (values) => {
    let isValidCaptcha = doSubmit(
      "#capt-res-contactUs",
      "user_captcha_input_contact"
    );

    if (isValidCaptcha) {
      setLoading(true);
      const res = await postData(values, URLS.forgotPassword);
      if (res) {
        setLoading(false);
        setDone(true);
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <div className="h-screen w-screen flex justify-center ">
      {!done ? (
        <div className="mt-48">
          <div className="flex flex-col text-start font-semibold sm:text-2xl w-full justify-center md:items-center text-green-800 ">
            Maha Kumbh Mela 2025
          </div>

          <Form
            onFinish={onFinish}
            className="max-w-96 border border-gray-200 h-fit p-2 mt-2"
            layout="vertical"
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Pease enter your email!" },
                {
                  type: "email",
                },
              ]}
              label={
                <div className="text-sm">
                  <div className="text-xl text-center mb-3 font-semibold">
                    Reset your password
                  </div>
                  <div>
                    Enter your user account's verified email address and we will
                    send you a password reset link
                  </div>
                </div>
              }
            >
              <Input placeholder="Enter Your Email Address"></Input>
            </Form.Item>

            <div className=" w-full flex items-start justify-end gap-3">
              <LoadCanvasTemplate />
              <div className="w-36 mt-2 ">
                <Form.Item name="user_captcha_input_contact">
                  <Input
                    onInput={() => handleOnInput("#capt-res-contactUs")}
                    placeholder="Enter Captcha Value"
                    id="user_captcha_input_contact"
                    name="user_captcha_input_contact"
                    type="text"
                  ></Input>
                </Form.Item>
                <div
                  id="capt-res-contactUs"
                  className="text-red-500 -mt-5"
                ></div>
              </div>
            </div>
            <Form.Item noStyle>
              <div className="flex justify-end w-full">
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="bg-green-500 text-white font-semibold w-full mt-3"
                >
                  Send
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <div className="max-w-96 relative top-1/4 border border-gray-200 h-fit p-3 text-center">
          <div>
            Check your email for a link to reset your password. If it doesn’t
            appear within a few minutes, check your spam folder.
          </div>
          <div className="w-full mt-3 flex items-center justify-center">
            <Link to="/login" className="w-full">
              <Button className="w-full">Return to sign in</Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
