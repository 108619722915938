// slices/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";
const employeeSlice = createSlice({
  name: "employeeSlice",
  initialState: {
    employeeUpdateEl: null,
  },
  reducers: {
    setUpdateEmployeeEl: (state, action) => {
      state.employeeUpdateEl = action.payload;
    },
  },
});

export const { setUpdateEmployeeEl } = employeeSlice.actions;
export default employeeSlice.reducer;
