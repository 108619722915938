// slices/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";
const centerSlice = createSlice({
  name: "centerSlice",
  initialState: {
    centerUpdateEl: null,
  },
  reducers: {
    setUpdateCenterEl: (state, action) => {
      state.centerUpdateEl = action.payload;
    },
  },
});

export const { setUpdateCenterEl } = centerSlice.actions;
export default centerSlice.reducer;
