import { Button, Image, Modal, Spin } from "antd";
import React, { useState, useEffect, useRef } from "react";
import URLS from "../../Fetch/URLS";
import { truncateString } from "../../utils/commonFunctions";
import DataTableModal from "../../search/lost_person/LostFoundModel";
import { langingPage } from "../../utils/dictionary";
import { useOutletContext } from "react-router";
import { getData } from "../../Fetch/Axios";

const FoundPersonsByName = ({ vals }) => {
  const [details, setDetails] = useState({ lost: [], found: [] });

  const handlePersons = async () => {
    if (vals?.first_name?.length >= 3 || vals?.last_name?.length >= 3) {
      let q = "?";

      if (vals?.first_name) {
        q = q + "&first_name=" + vals.first_name;
      }

      if (vals?.last_name) {
        q = q + "&last_name=" + vals.last_name;
      }

      if (vals?.complainant_mob_number) {
        q = q + "&complainant_mob_number=" + vals.complainant_mob_number;
      }

      if (vals?.dob && vals.dob !== "Invalid Date") {
        q = q + "&dob=" + vals.dob.split("T")[0];
      }

      if (vals?.city_id) {
        q = q + "&city_id=" + vals.city_id;
      }

      if (vals?.attire_id) {
        q = q + "&attire_id=" + vals.attire_id;
      }

      if (vals?.attire_color_id) {
        q = q + "&attire_color_id=" + vals.attire_color_id;
      }

      if (q) {
        let foundDetails = await getData(URLS.lostFoundSearch + q);

        if (foundDetails?.found) {
          setDetails(() => {
            return { lost: foundDetails?.lost, found: foundDetails?.found };
          });
        } else {
          setDetails([]);
        }
      }
    } else {
      setDetails({ lost: [], found: [] });
    }
  };

  const debounceTimeout = useRef();

  useEffect(() => {
    if (vals) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        handlePersons();
      }, 1000);
    }

    // Cleanup timeout when component unmounts or vals changes
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [vals]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  const [modal, setModal] = useState(false);

  const handleCancel = () => {
    setModal(false);
  };

  const columns = [
    {
      title: "Ticket Number",
      dataIndex: "complaint_number",
      key: "complaint_number",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "name",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Sector",
      dataIndex: "center_id",
      key: "sector",
      render: (val) => {
        return val?.sector_id?.name || "-";
      },
    },
  ];

  return (
    <Spin spinning={false}>
      <div className="border flex flex-col w-full h-f">
        <div className="text-green-700 w-full font-semibold text-center items-center justify-center p-1 bg-[#ffd8bf]">
          {dict.name_based_matches[lang]}
        </div>

        {(details?.lost?.length !== 0 || details?.found?.length !== 0) && (
          <div
            className="grid grid-cols-1 xl:grid-cols-2 gap-2 items-start  p-1 h-full bg-[#fff2e8] overflow-scroll"
            style={{
              maxHeight: "500px",
            }}
          >
            {details.lost?.slice(0, 4).map((el) => {
              return (
                <div key={el.name}>
                  <div className="flex flex-col justify-center items-center w-52 lg:w-full max-h-52  m-auto overflow-hidden bg-orange-100 ">
                    <div className="h-28 overflow-hidden justify-center border">
                      <Image
                        className="w-full"
                        src={URLS.lost_photo_base_url + el.photo}
                        alt={el.photo}
                      ></Image>
                    </div>
                    <div
                      className="m-auto text-center text-base flex w-full flex-col bg-white"
                      onClick={() => {
                        setIsModalVisible(() => el);
                      }}
                    >
                      <div className=" w-full text-center ">
                        {truncateString(el.first_name + " " + el.last_name, 20)}
                      </div>
                      <div className="p-1 -mt-2 w-full text-center ">
                        {el?.complaint_number}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            {details.found?.slice(0, 4).map((el) => {
              return (
                <div key={el.name}>
                  <div className="flex flex-col justify-center items-center w-52 lg:w-full max-h-52  m-auto overflow-hidden bg-orange-100 ">
                    <div className="h-32 overflow-hidden justify-center">
                      <Image
                        className="w-full "
                        src={URLS.found_photo_base_url + el.photo}
                        alt={el.photo}
                      ></Image>
                    </div>
                  </div>
                  <div
                    className="m-auto text-center text-base flex w-full flex-col bg-white"
                    onClick={() => {
                      setIsModalVisible(() => el);
                    }}
                  >
                    <div className=" w-full text-center ">
                      {truncateString(el.first_name + " " + el.last_name, 20)}
                    </div>

                    <div className="p-1 -mt-2 w-full text-center ">
                      {el?.complaint_number}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <Modal
          title="Name Based Matches"
          centered
          width={1000}
          open={modal}
          onOk={handleCancel}
          onCancel={handleCancel}
          footer={false}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div className="">
              <div className="h-7 bg-orange-300 text-teal-600 font-bold text-lg text-center">
                Lost Cases
              </div>
              <div className="h-96 overflow-y-scroll border">
                {details?.lost?.length !== 0 && (
                  <div>
                    <div
                      className="grid grid-cols-2 lg:grid-cols-3 gap-2 items-start  p-1  overflow-scroll "
                      style={{
                        maxHeight: "500px",
                      }}
                    >
                      {details.lost?.slice(0, 4).map((el) => {
                        return (
                          <div key={el.name}>
                            <div className="flex flex-col justify-center items-center w-52 lg:w-full max-h-52  m-auto overflow-hidden bg-teal-100 ">
                              <div className="h-32 overflow-hidden justify-center">
                                <Image
                                  className="w-full"
                                  src={URLS.lost_photo_base_url + el.photo}
                                  alt={el.photo}
                                ></Image>
                              </div>
                              <div
                                className="m-auto text-center text-base flex w-full flex-col "
                                onClick={() => {
                                  setIsModalVisible(() => el);
                                }}
                              >
                                <div className=" w-full text-center ">
                                  {truncateString(
                                    el.first_name + " " + el.last_name,
                                    20
                                  )}
                                </div>
                                <div className="p-1 -mt-2 w-full text-center ">
                                  {el?.complaint_number}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="h-7 bg-green-300 text-teal-600 font-bold text-lg text-center">
                Found Cases
              </div>
              <div className="h-96 overflow-y-scroll border">
                {details?.found?.length !== 0 && (
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 items-start  p-1 h-full overflow-scroll ">
                    {details.found?.slice(0, 4).map((el) => {
                      return (
                        <div key={el.name}>
                          <div className="flex flex-col justify-center items-center w-52 lg:w-full max-h-52  m-auto overflow-hidden  bg-teal-100">
                            <div className="h-32 overflow-hidden justify-center">
                              <Image
                                className="w-full "
                                src={URLS.found_photo_base_url + el.photo}
                                alt={el.photo}
                              ></Image>
                            </div>
                          </div>
                          <div
                            className="m-auto text-center text-base flex w-full flex-col bg-teal-100"
                            onClick={() => {
                              setIsModalVisible(() => el);
                            }}
                          >
                            <div className=" w-full text-center ">
                              {truncateString(
                                el.first_name + " " + el.last_name,
                                20
                              )}
                            </div>
                            <div className="p-1 -mt-2 w-full text-center ">
                              {el?.complaint_number}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>

        {!details?.lost?.length && !details?.found?.length && (
          <div className="text-red-600 border flex w-full font-semibold h-64 justify-center items-center">
            {dict.no_records_found[lang]}
          </div>
        )}

        {(details.found?.length !== 0 || details.lost?.length !== 0) && (
          <Button
            onClick={() => setModal(true)}
            className={`flex bg-orange-300 font-semibold text-green-900  mt-auto w-full justify-center pt-2 `}
          >
            View All
          </Button>
        )}
      </div>

      <DataTableModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        onlyLimited={false}
      ></DataTableModal>
    </Spin>
  );
};

export default FoundPersonsByName;
