import React from "react";
import { MapContainer, TileLayer, Circle, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { langingPage } from "../utils/dictionary";
import { useOutletContext } from "react-router";

const PrayagrajMap = () => {
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang =  outlet[1];

  // Coordinates for Prayagraj city center
  const prayagrajCenter = [25.4358, 81.8463];

  // Coordinates for the four locations
  const locations = [
    {
      coords: [25.4358, 81.8563],
      color: "#ff9999",
      label: "High-density Lost Area 1",
    }, // Slightly denser red
    {
      coords: [25.4458, 81.8363],
      color: "#99ff99",
      label: "High-density Found Area 1",
    }, // Slightly denser green
    {
      coords: [25.4258, 81.8463],
      color: "#ff9999",
      label: "High-density Lost Area 2",
    }, // Slightly denser blue
    {
      coords: [25.4558, 81.8263],
      color: "#99ff99",
      label: "High-density Found Area 2",
    }, // Slightly denser yellow
  ];

  return (
    <div className="p-1 shadow-md mt-3 z-10">
      <div className="sm:flex justify-between m-2">
        <div className="flex items-center text-lg md:text-xl lg:text-2xl font-semibold">
          {dict.locations_of_lost_and_found_cases[lang]}
        </div>
        <div className="flex gap-3 m-1 z-100 ml-auto justify-end">
          <div className="flex justify-center items-center gap-1">
            <div
              className="h-4 w-4 rounded-full border-2 border-green-500"
              style={{ background: "#99ff99" }}
            ></div>
            <span className="text-xs font-semibold">{dict.high_density_found[lang]}</span>
          </div>
          <div className="flex justify-center items-center gap-1">
            <div
              className="h-4 w-4 rounded-full border-2 border-red-500"
              style={{ background: "#ff9999" }}
            ></div>
            <span className="text-xs font-semibold">{dict.high_density_lost[lang]}</span>
          </div>{" "}
        </div>
      </div>
      <MapContainer
        center={prayagrajCenter}
        zoom={13}
        style={{ height: "60vh", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {locations.map((location, index) => (
          <Circle
            key={index + location.coords}
            center={location.coords}
            radius={300} // Smaller radius
            color={location.color}
            fillColor={location.color}
            fillOpacity={0.5} // Slightly denser fill color
          >
            <Popup>{location.label}</Popup>
          </Circle>
        ))}
      </MapContainer>
    </div>
  );
};

export default PrayagrajMap;
