import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import calenderIcon from "../assets/Dashboard/calendarIcon.png";
import { Link } from "react-router-dom";
import { langingPage } from "../utils/dictionary";

const NavHead = ({ lang, setLang }) => {
  const myDate = new Date();
  const dict = langingPage;

  const options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const handleLang = () => {
    if (lang === "en") {
      localStorage.setItem("lang", "hi");
      setLang("hi");
    } else {
      localStorage.setItem("lang", "en");
      setLang("en");
    }
  };

  const navigate = useNavigate();

  const [logName, setLogName] = useState(false);
  const loggedIn = localStorage.getItem("sessionToken");
  const formattedDate = myDate.toLocaleDateString("en-GB", options);

  useEffect(() => {
    if (loggedIn) {
      setLogName(true);
    }
  }, []);

  const handleNavigation = (link) => {
    if (link.includes("welcome")) {
      let id = setInterval(() => {
        if (!localStorage.getItem("sessionToken")) {
          clearInterval(id);
          setLogName(false);
          navigate(link);
        }
      }, 100);
    } else {
      navigate(link);
    }
  };

  return (
    <div className="relative top-0 bg-[#ffa940]">
      <div
        className="font-merriweather flex lg:grid grid-cols-1 md:grid-cols-3 justify-between mx-3 my-1 m-auto mb-1 "
        style={{ height: "73px" }}
      >
        <div className="gap-1 justify-start col-span-1 hidden md:flex items-center">
          <div
            style={{ width: "180px" }}
            className="hidden text-sm bg-white  h-8  p-1 px-2 md:flex gap-1 justify-center items-center rounded"
          >
            <img src={calenderIcon} alt="" />
            <span className="text-xs font-nutino flex justify-center items-center ">
              {formattedDate}
            </span>
          </div>
        </div>

        <Link to="/" className="no-underline flex flex-col">
          <div className="flex flex-col text-start font-semibold sm:text-2xl w-full justify-center md:items-center text-green-800 ">
            {dict.maha_kumbh_mela_2025[lang]}
          </div>
          <hr className="mt-1 mb-1 text-black" />
          <div className="text-center sm:text-2xl text-black font-semibold">
            {dict.lost_and_found_tracking[lang]}
          </div>
        </Link>

        <div className="flex justify-end items-center gap-3 text-sm">
          <div>
            <div
              className="text-black cursor-pointer bg-white rounded p-1 w-full h-full text-start no-underline"
              onClick={handleLang}
              style={{ width: "70px" }}
            >
              ENG/हिंदी{" "}
            </div>
          </div>

          <div>
            {!logName ? (
              <div onClick={() => handleNavigation("/login")}>
                <div className="flex items-center text-black cursor-pointer  w-full justify-start  bg-white rounded p-1">
                  <div className="font-semibold text-sm">Login</div>
                </div>
              </div>
            ) : (
              <div>
                <div
                  onClick={() => {
                    localStorage.clear();
                    handleNavigation("/welcome-to-lost-and-found-2025");
                  }}
                >
                  <div className="flex items-center text-black cursor-pointer  w-full justify-start  bg-white rounded p-1">
                    <div className="font-semibold text-sm">
                      {dict.logout[lang]}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavHead;
