import { BarChartOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import "./tabStyle.css";
import { langingPage } from "../utils/dictionary";
import { useOutletContext } from "react-router";

const getChart = (currentSeries, details, lang, age = []) => {
  const dict = langingPage;
  const options = {
    chart: {
      type: "bar",
      stacked: true,
      height: 350,
      toolbar: {
        show: false,
        tools: {
          download: false, // Disable the download button
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    colors: age[0]
      ? ["#FDFD96", "#B5EAD7", "#DCC6E0"]
      : ["#6666FF", "#FFD1DC", "#FFC04D"], // Light Blue, Light Pink, Light Orange

    dataLabels: {
      enabled: true, // Enable data labels
      formatter: function (val) {
        return val === 0 ? "" : val; // Hide the label if value is 0
      },
      style: {
        fontSize: "12px",
        colors: ["#000"], // Optional: Set label color
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        dict.sun[lang],
        dict.mon[lang],
        dict.tue[lang],
        dict.wed[lang],
        dict.thur[lang],
        dict.fir[lang],
        dict.sat[lang],
      ],
    },
    yaxis: {
      title: {
        text: dict.values[lang],
      },
      min: 0,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    title: {
      display: true,
      text: ``,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
    },
  };

  const series = [
    // {
    //   name: "Children",
    //   data: [70, 48, 76, 35, 66, 57, 75],
    // },
    {
      name: age[0] || "Male",
      data: [95, 85, 74, 56, 64, 75, 85],
    },
    {
      name: age[1] || "Female",
      data: [90, 87, 78, 52, 69, 45, 85],
    },
    // {
    //   name: "Senior Citizen",
    //   data: [70, 48, 76, 35, 66, 57, 75],
    // },
    {
      name: age[2] || "Other",
      data: [70, 48, 76, 35, 66, 57, 75],
    },
  ];

  const series4 = [
    {
      name: age[0] || "Male",
      data: [35, 11, 14, 16, 25, 16, 28],
    },
    {
      name: age[1] || "Female",
      data: [39, 12, 39, 13, 25, 12, 33],
    },
    // {
    //   name: "Children",
    //   data: [13, 21, 21, 11, 13, 17, 29],
    // },
    {
      name: age[2] || "Other",
      data: [70, 48, 76, 35, 66, 57, 75],
    },
  ];

  const series3 = [
    {
      name: age[0] || "Male",
      data: [20, 16, 22, 13, 19, 12, 20],
    },
    {
      name: age[1] || "Female",
      data: [18, 17, 22, 22, 23, 11, 40],
    },
    // {
    //   name: "Children",
    //   data: [14, 14, 16, 11, 18, 13, 24],
    // },
    {
      name: age[2] || "Other",
      data: [70, 48, 76, 35, 66, 57, 75],
    },
  ];

  const series2 = [
    {
      name: age[0] || "Male",
      data: [40, 58, 38, 27, 20, 47, 37],
    },
    {
      name: age[1] || "Female",
      data: [33, 58, 17, 17, 21, 22, 12],
    },
    // {
    //   name: "Children",
    //   data: [43, 13, 39, 13, 35, 27, 22],
    // },
    {
      name: age[2] || "Other",
      data: [70, 48, 76, 35, 66, 57, 75],
    },
  ];

  let final = { s1: series, s2: series2, s3: series3, s4: series4 };

  if (details) {
    final = details;
  }

  const chart = (
    <div className="px-3">
      <Chart
        options={options}
        series={
          currentSeries === "s1"
            ? final.s1
            : currentSeries === "s2"
            ? final.s2
            : currentSeries === "s3"
            ? final.s3
            : final.s4
        }
        type="bar"
        height={350}
      />
    </div>
  );
  return chart;
};

const GroupedBarChart = ({ details, setSeries, age = [] }) => {
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  useEffect(() => {
    const el = document.getElementsByClassName("ant-tabs-tab");
    for (const element of el) {
      element.addEventListener("click", () => {
        setSeries(() => element.getAttribute("data-node-key"));
      });
    }
  }, []);

  return (
    <div className="w-full py-2">
      <div>
        <Tabs
          size="large"
          defaultActiveKey="2"
          items={[
            {
              key: "s1",
              label: (
                <div
                  onClick={() => {
                    setSeries(() => "s1");
                  }}
                  className="font-semibold md:text-base text-sm h-full "
                >
                  <span className="mr-2 rounded-md p-2 bg-green-100">
                    <BarChartOutlined></BarChartOutlined>
                  </span>
                  {dict.total_lost[lang]}
                </div>
              ),
              children: (
                <div>
                  <div
                    className="flex w-fill justify-end"
                    style={{ marginTop: "30px" }}
                  ></div>
                  {getChart("s1", details, lang, age)}
                </div>
              ),
            },
            {
              key: "s2",
              label: (
                <div
                  onClick={() => {
                    setSeries(() => "s2");
                  }}
                  className="font-semibold md:text-base text-sm"
                >
                  <span className="mr-2 rounded-md p-2 bg-green-100">
                    <BarChartOutlined></BarChartOutlined>
                  </span>{" "}
                  {dict.total_found[lang]}
                </div>
              ),
              children: (
                <div>
                  <div
                    className="flex w-fill justify-end"
                    style={{ marginTop: "30px" }}
                  ></div>
                  {getChart("s2", details, lang, age)}
                </div>
              ),
            },
            {
              key: "s3",
              label: (
                <div
                  onClick={() => {
                    setSeries(() => "s3");
                  }}
                  className="font-semibold md:text-base text-sm"
                >
                  <span className="mr-2 rounded-md p-2 bg-green-100">
                    <BarChartOutlined></BarChartOutlined>
                  </span>{" "}
                  {dict.reunite[lang]}
                </div>
              ),
              children: (
                <div>
                  <div
                    className="flex w-fill justify-end"
                    style={{ marginTop: "30px" }}
                  ></div>
                  {getChart("s3", details, lang, age)}
                </div>
              ),
            },
            {
              key: "s4",
              label: (
                <div
                  onClick={() => {
                    setSeries(() => "s4");
                  }}
                  className="font-semibold md:text-base text-sm"
                >
                  <span className="mr-2 rounded-md p-2 bg-green-100">
                    <BarChartOutlined></BarChartOutlined>
                  </span>{" "}
                  {dict.unresolved[lang]}
                </div>
              ),
              children: (
                <div>
                  <div
                    className="flex w-fill justify-end"
                    style={{ marginTop: "30px" }}
                  ></div>
                  {getChart("s4", details, lang, age)}
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default GroupedBarChart;
