import React, { useState } from "react";
import imageCompression from "browser-image-compression";
import Resizer from "react-image-file-resizer";
import { langingPage } from "../utils/dictionary";
import { useOutletContext } from "react-router";
import { resizeImage } from "../utils/commonFunctions";

const ImageCompressor = ({ setImageSrcMain }) => {
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      await resizeImage(file, setImageSrcMain);
    } catch (error) {
      console.error("Image compression error:", error);
    }
  };

  // const resizeImage = (file) => {
  //   Resizer.imageFileResizer(
  //     file,
  //     1920, // New width
  //     1080, // New height
  //     "JPEG", // Output format
  //     100, // Quality (0-100)
  //     0,
  //     (uri) => {
  //       setImageSrcMain(uri);
  //     },
  //     "base64"
  //   );
  // };

  return (
    <div>
      <label className="custom-file-upload">
        <input type="file" accept="image/*" onChange={handleImageUpload} />
        {dict.upload_image[lang]}
      </label>
    </div>
  );
};

export default ImageCompressor;
