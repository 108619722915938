import { Button, message, notification } from "antd";
import React, { useState, useRef, useEffect } from "react";
import pause from "../assets/Images/audioRecoder/pause.png";
import recorder from "../assets/Images/audioRecoder/recorder.png";
import TextTranslation from "./TextTranslation";
import bhashini from "../Fetch/BhashiniCredentials";

const AudioToConverter = ({
  sourceLanguage,
  targetLanguage,
  gender,
  onlyText = true,
}) => {
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [uploading, setUploading] = useState(false);
  const [base64Audio, setBase64Audio] = useState("");
  const mediaRecorderRef = useRef(null);
  const mediaStreamRef = useRef(null); // Add a ref to store the media stream

  // ---------------
  const [inputText, setInputText] = useState();

  const audioChunksRef = useRef([]);
  const [audioSrc, setAudioSrc] = useState();
  const [speechToText, setSpeechToText] = useState({ source: "", target: "" });

  // Error Notification
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: "Note",
      duration: 7,
      description:
        "Please Select the Source language, Target and gender language to proceed.",
    });
  };

  const openNotificationWithIcon1 = (type) => {
    api[type]({
      message: "Error",
      duration: 7,
      description:
        "We are unable to support audio translation for the chosen language at this time!",
    });
  };

  const handleStopRecording = () => {
    mediaRecorderRef.current.stop();
    mediaStreamRef.current.getTracks().forEach((track) => track.stop());

    setRecording(false);
  };

  // reset all fields to avoid miss translation
  useEffect(() => {
    if (sourceLanguage) {
      setAudioURL(false);
      setAudioSrc(false);
      setUploading(false);
    }
  }, [sourceLanguage, sourceLanguage?.sourceLanguage]);

  const handleStartRecording = async () => {
    if (!sourceLanguage || !targetLanguage || !gender) {
      openNotificationWithIcon("info");
      return;
    }

    setAudioURL(false);
    setAudioSrc(true);
    setUploading(false);

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    mediaStreamRef.current = stream;
    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorderRef.current.onstop = async () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioURL(audioUrl);
      audioChunksRef.current = [];

      const arrayBuffer = await audioBlob.arrayBuffer();
      const base64 = bufferToBase64(arrayBuffer);
      setBase64Audio(base64);
    };

    mediaRecorderRef.current.start();
    setRecording(true);
  };

  const bufferToBase64 = (buffer) => {
    const bytes = new Uint8Array(buffer);
    let binary = "";
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
  };

  const handleUploadAudio = async () => {
    if (!base64Audio) return;

    setUploading(true);

    const payload = {
      modelId: sourceLanguage?.serviceModel?.modelId,
      task: "asr",
      source: sourceLanguage.sourceLanguage,
      audioContent: base64Audio,
      userId: bhashini.userId,
    };

    try {
      const response1 = await fetch(
        "https://meity-auth.ulcacontrib.org/ulca/apis/asr/v1/model/compute",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const result1 = await response1.json();

      if (result1) {
        try {
          const ttsPayload = {
            pipelineTasks: [
              {
                taskType: "translation",
                config: {
                  language: {
                    sourceLanguage: sourceLanguage.sourceLanguage,
                    targetLanguage: targetLanguage,
                  },
                },
              },
            ],
            inputData: {
              input: [
                {
                  source: result1.data.source,
                },
              ],
            },
          };

          const response2 = await fetch(
            "https://dhruva-api.bhashini.gov.in/services/inference/pipeline",
            {
              method: "POST",
              headers: {
                Authorization: bhashini.apiKey,
                "Content-Type": "application/json",
                ulcaApiKey: bhashini.udyatKey,
                userId: bhashini.userId,
              },
              body: JSON.stringify(ttsPayload),
            }
          );

          const result2 = await response2.json();

          if (result2) {
            setSpeechToText(() => {
              return {
                source: result2.pipelineResponse[0].output[0].source,
                target: result2.pipelineResponse[0].output[0].target,
              };
            });

            const ttsPayload = {
              pipelineTasks: [
                {
                  taskType: "tts",
                  config: {
                    language: {
                      sourceLanguage: targetLanguage,
                    },
                    gender: gender,
                    samplingRate: 8000,
                  },
                },
              ],
              inputData: {
                input: [
                  {
                    source: result2.pipelineResponse[0].output[0].target,
                  },
                ],
              },
            };

            const response3 = await fetch(
              "https://dhruva-api.bhashini.gov.in/services/inference/pipeline",
              {
                method: "POST",
                headers: {
                  Authorization: bhashini.apiKey,
                  ulcaApiKey: bhashini.udyatKey,
                  userId: bhashini.userId,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(ttsPayload),
              }
            );

            const result3 = await response3.json();

            playAudioFromBase64(
              result3.pipelineResponse[0].audio[0].audioContent
            );
          }
        } catch (error) {
          openNotificationWithIcon1("info");
        }
      }
    } catch (err) {
      openNotificationWithIcon1("info");
    } finally {
      setUploading(false);
    }
  };

  function playAudioFromBase64(base64String) {
    const binaryString = atob(base64String);
    const len = binaryString.length;
    const arrayBuffer = new ArrayBuffer(len);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < len; i++) {
      view[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: "audio/wav" });
    const src = URL.createObjectURL(blob);
    setAudioSrc(src);
  }

  useEffect(() => {
    return () => {
      // Stop the media recorder if it is active
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current.stop();
      }
      // Stop all media stream tracks
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);
  // ------- Input As Text --------
  const [loading, setLoading] = useState({
    loading: false,
    disableConvert: true,
  });

  useEffect(() => {
    if (inputText) {
      const textToAudio = async (text) => {
        setLoading(() => {
          return { loading: true, disableConvert: true };
        });

        try {
          const ttsPayload = {
            pipelineTasks: [
              {
                taskType: "translation",
                config: {
                  language: {
                    sourceLanguage: sourceLanguage.sourceLanguage,
                    targetLanguage: targetLanguage,
                  },
                },
              },
            ],
            inputData: {
              input: [
                {
                  source: text,
                },
              ],
            },
          };

          const response2 = await fetch(
            "https://dhruva-api.bhashini.gov.in/services/inference/pipeline",
            {
              method: "POST",
              headers: {
                Authorization: bhashini.apiKey,
                ulcaApiKey: bhashini.udyatKey,
                userId: bhashini.userId,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(ttsPayload),
            }
          );

          const result2 = await response2.json();

          if (result2) {
            setSpeechToText(() => {
              return {
                source: result2.pipelineResponse[0].output[0].source,
                target: result2.pipelineResponse[0].output[0].target,
              };
            });

            const ttsPayload = {
              pipelineTasks: [
                {
                  taskType: "tts",
                  config: {
                    language: {
                      sourceLanguage: targetLanguage,
                    },
                    gender: gender,
                    samplingRate: 8000,
                  },
                },
              ],
              inputData: {
                input: [
                  {
                    source: result2.pipelineResponse[0].output[0].target,
                  },
                ],
              },
            };

            const response3 = await fetch(
              "https://dhruva-api.bhashini.gov.in/services/inference/pipeline",
              {
                method: "POST",
                headers: {
                  Authorization: bhashini.apiKey,
                  ulcaApiKey: bhashini.udyatKey,
                  userId: bhashini.userId,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(ttsPayload),
              }
            );

            const result3 = await response3.json();
            if (result3) {
              setLoading(() => {
                return { loading: false, disableConvert: true };
              });
            }

            playAudioFromBase64(
              result3.pipelineResponse[0].audio[0].audioContent
            );
          }
        } catch (error) {
          openNotificationWithIcon1("info");
          setLoading(() => {
            return { loading: false, disableConvert: true };
          });
        }
      };

      textToAudio(inputText);
    }
  }, [inputText]);

  return (
    <>
      {contextHolder}
      <TextTranslation
        speechToText={speechToText}
        onlyText={onlyText}
        setInputText={setInputText}
        setLoading={setLoading}
        loading={loading}
      ></TextTranslation>
      <div className="grid grid-cols-1 w-full md:grid-cols-2 gap-3 mt-3 mx-auto">
        <div className=" flex border-1 rounded  items-between flex-col bg-blue-50">
          <div className=" w-full flex justify-between items-center p-2  rounded-t bg-blue-100 shadow-md">
            <div className="font-bold  text-sm">Hello</div>
          </div>
          <div className="flex flex-col items-center justify-center pt-3 ">
            {recording ? (
              <div className="flex flex-col items-center justify-center ">
                <Button
                  className="border-none bg-transparent h-10"
                  onClick={handleStopRecording}
                >
                  <img src={pause} className="h-full" alt="" />
                </Button>

                <div className=" flex items-center justify-center ">
                  <div className="relative">
                    <div className=" absolute top-0 left-0 w-4 h-4 bg-red-600 rounded-full animate-ping"></div>
                    <div className="w-4 h-4 bg-red-600 rounded-full"></div>
                  </div>
                  <div className="ml-2 text-xs text-red-500 font-semibold">
                    Recording...
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full flex justify-center flex-col items-center gap-1">
                <Button
                  className="border-none bg-transparent h-10"
                  onClick={handleStartRecording}
                >
                  <img src={recorder} className="h-full" alt="" />
                </Button>

                {/* <audio
                    controls
                    src={audioURL.toString()}
                    className="w-full max-w-xs mb-2"
                  ></audio> */}
              </div>
            )}
          </div>
          <div>
            <div className="flex mt-1 justify-center w-full h-full items-center p-1  mb-2">
              <button
                onClick={handleUploadAudio}
                className={`px-2 py-1 text-xs text-white rounded-full ${
                  audioURL ? "bg-green-500" : "bg-gray-400"
                } hover:${
                  audioURL ? "bg-green-600" : "bg-gray-500"
                } transition`}
                disabled={uploading && audioURL}
              >
                {uploading ? "Converting..." : "Convert"}
              </button>
            </div>
          </div>
        </div>
        <div className="bg-green-50">
          <div className=" flex border-1 rounded  items-between flex-col w-full h-full">
            <div className=" w-full flex justify-between items-center p-2  rounded-t bg-green-100 shadow-md">
              <div className="font-bold  text-sm">Transcribe </div>
            </div>
            <div className="flex items-center justify-center h-full pb-3 mt-3 rounded-b">
              <audio
                controls
                src={audioSrc}
                style={{ width: "150px", height: "30px" }} // Adjust the width and height
              ></audio>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AudioToConverter;
