import React, { useEffect } from "react";
import CommonDivider from "../../commonComponents/CommonDivider";
import CommonSearchForm from "../../commonComponents/CommonSearchForm";
import { useState } from "react";
import CommonTable from "../../commonComponents/CommonTable";
import { Form } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router";
import { FaUserEdit } from "react-icons/fa";
import { setUpdateRoleEl } from "./roleSlice";
import { langingPage } from "../../utils/dictionary";

const Role = ({ permissions }) => {
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];
  const [searchQuery, setSearchQuery] = useState();

  const [columns, setColumns] = useState([
    {
      title: dict.name[lang],
      dataIndex: "name",
      key: "name",
      width: 200,
    },
  ]);

  useEffect(() => {
    const updatedColumns = [
      {
        title: dict.name[lang],
        dataIndex: "name",
        key: "name",
        width: 200,
      },
    ];

    if (permissions?.update) {
      updatedColumns.push({
        title: dict.action[lang],
        dataIndex: "action",
        key: "action",
        width: 100,
        fixed: "right",
      });
    }

    setColumns(updatedColumns);
  }, [permissions, lang]);

  // useEffect(() => {
  //   if (permissions?.update) {
  //     setColumns((prev) => {
  //       return [
  //         ...prev,
  //         {
  //           title: dict.action[lang],
  //           dataIndex: "action",
  //           key: "action",
  //           width: 100,
  //           fixed: "right",
  //         },
  //       ];
  //     });
  //   }
  // }, [permissions]);

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const actionFun = (el) => {
    return (
      <div
        className="border-none border flex w-10 p-1 justify-center items-center"
        onClick={() => {
          delete el["action"];
          dispatch(setUpdateRoleEl(el));
          navigate("/role-registration/" + el._id);
        }}
      >
        <span className="justify-center items-center">
          <FaUserEdit className="text-2xl text-orange-400" />
        </span>
      </div>
    );
  };

  return (
    <div>
      <CommonDivider
        label={dict.role[lang]}
        compo={permissions.create && { path: "/role-registration" }}
      ></CommonDivider>

      <CommonSearchForm
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        form={form}
        fields={[{ name: "name", label: dict.name[lang] }]}
      ></CommonSearchForm>

      <CommonTable
        uri="role"
        reactBaseUrl="role"
        columns={columns}
        extraParams={searchQuery}
        form={form}
        scroll={{ x: 100, y: 400 }}
        actionFun={actionFun}
      ></CommonTable>
    </div>
  );
};

export default Role;
